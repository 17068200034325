import type CartVM from '@/react/view-models/CartVM';
import { useCallback, type FunctionComponent } from 'react';
import { writePlacedOrderToSession } from '@/react/utils/checkout-utils/writePlacedOrderToSession';
import { withPreventUnload } from '@/react/utils/dom-utils';
import { getBrowserData } from '@/react/utils/getBrowserData';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PaymentInterruptedError } from '@/services/isomorphic/PaymentRequestService';
import type { IGetExpressCheckoutDetailsResponse } from '@/services/serverless/integrations/ServerPayPalNVPService';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import { PayPalButton } from '../PayPalButton';

export interface IExpressCheckoutWithPayPalButtonProps {
  /** The CartVM representing the current cart.   */
  cart: CartVM;
}

/**
 * Renders a {@link PayPalButton} preconfigured for Express Checkout.
 *
 * When clicked, it will attempt to create an order from provided cart and place
 * it when the user approves the transaction.
 */
export const ExpressCheckoutWithPayPalButton: FunctionComponent<
  IExpressCheckoutWithPayPalButtonProps
> = ({ cart }) => {
  const router = useRouter();

  // On approval, PayPal buttons call their `onApprove` handler with the result
  // of a `GetExpressCheckoutDetails` call for the order being approved.
  const onApprove = useCallback(
    async (details: IGetExpressCheckoutDetailsResponse) => {
      try {
        await withPreventUnload(async () => {
          const browserData = getBrowserData();
          const placedOrder = await cart.placePayPalOrder(details, browserData);

          writePlacedOrderToSession(placedOrder);

          await router.push(
            `/checkout/confirmation?token=${placedOrder.orderToken}`
          );

          /**
           * **Note**: The cart must be emptied *after* we navigate to the
           * confirmation page. This is because the checkout page is "guarded"
           * to send the user back to the cart page if their cart is empty. As a
           * result, if we empty the cart before navigating to the confirmation
           * page, the user will be redirected back to the cart page first.
           */
          await cart.empty();
        });
      } catch (error) {
        if (error instanceof PaymentInterruptedError) {
          LoggerService.error(error);
          return;
        }
        throw error;
      }
    },
    [cart, router]
  );

  return <PayPalButton mode="express" cart={cart} onApprove={onApprove} />;
};
