import type { FC } from 'react';
import { msg } from '@/services/isomorphic/I18NService';
import { IconTypes, Icon } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import type { IAccountUtilityLinkProps } from './IAccountUtilityLinkProps';

import { MobileDrawer } from '../../MobileDrawer';
import S from '../../../styles.base.module.scss';
import { MobileNavItem } from '../../MobileNavItem';
import { NavigationLink } from '../../NavigationLink';
import { header_utilBar_account } from "@/lang/__generated__/ahnu/header_utilBar_account";
import { login_title } from "@/lang/__generated__/ahnu/login_title";
import { account_title } from "@/lang/__generated__/ahnu/account_title";
import { account_createAccount_title } from "@/lang/__generated__/ahnu/account_createAccount_title";

/**
 * Component housing links and functionality related to user accounts (e.g. login, register, account, wishlist).
 * Will show different links based on whether the user is logged in or not.
 * @param isLoggedIn - Whether the user is logged in.
 */
export const AccountUtilityLink: FC<IAccountUtilityLinkProps> = ({
  isLoggedIn
}) => {
  return (
    <MobileDrawer
      labelClassName={S.utilityLink}
      label={msg(header_utilBar_account)}
      icon={IconTypes.User}
    >
      <ul className={S.nav}>
        <MobileNavItem>
          <span
            className={classes(S.navLink, S.navLinkHeader, S.labelContainer)}
          >
            <Icon icon={IconTypes.User} />
            {msg(header_utilBar_account)}
          </span>
        </MobileNavItem>
        <MobileNavItem>
          {!isLoggedIn ? (
            <NavigationLink
              item={{
                href: '/login',
                label: msg(login_title),
                id: 'login'
              }}
            />
          ) : (
            <NavigationLink
              item={{
                href: '/account',
                label: msg(account_title),
                id: 'my-account'
              }}
            />
          )}
        </MobileNavItem>
        {!isLoggedIn && (
          <MobileNavItem>
            <NavigationLink
              item={{
                href: '/login',
                label: msg(account_createAccount_title),
                id: 'register'
              }}
            />
          </MobileNavItem>
        )}
      </ul>
    </MobileDrawer>
  );
};
