/**
 * Placements are defined in the CM Studio
 * Site > Settings > PageGrid > Placements.
 */
enum CMSPlacementTypes {
  MarketingBanner = 'marketing-banner',
  Main = 'main',
  GenericContent = 'generic-content',
  FooterBanner = 'footer-banner',
  FooterSubItems = 'footer-subitems',
  FooterLinks = 'footer-links',
  PDPContent = 'pdp-content',
  Secondary = 'secondary',

  // PLP Placement Types
  PLPContentTop = 'plp-content-top',
  PLPContentBottom = 'plp-content-bottom',
  PLPContentTile1 = 'plp-content-tile-1',
  PLPContentTile2 = 'plp-content-tile-2',
  PLPContentTile3 = 'plp-content-tile-3',
  PLPContentTile4 = 'plp-content-tile-4'
}

export default CMSPlacementTypes;
