'use client';

import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';

/**
 * A hook to determine whether PayPal is available within the current context.
 * This should only be used within components with an `PayPalProvider` ancestor.
 *
 * "Available" means that the PayPal JS SDK has been loaded and the browser
 * supports PayPal.
 *
 * @returns `true` if PayPal is available, `false` otherwise.
 */
export function useIsPayPalAvailable(): {
  expressCheckoutAvailable: boolean;
  normalCheckoutAvailable: boolean;
} {
  const [{ isPending }] = usePayPalScriptReducer();
  const config = ConfigurationService.getConfig('paypal');

  const enabled = config.getSetting('enabled').value;
  const enableExpressCheckout =
    enabled && config.getSetting('enableExpressCheckout').value;

  return {
    expressCheckoutAvailable: enableExpressCheckout && !isPending,
    normalCheckoutAvailable: enabled && !isPending
  };
}
