'use client';

import 'setimmediate';
import { DTO, Nullable } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import type { InputHTMLAttributes } from 'react';

import Model from '@/services/models/Model';
import { ISubformMetadata } from './subform';
import {
  IValidatableOptions,
  IValidationResult,
  runValidations
} from './validatable';

/** Object that contains the errors of each property (if any). */
export interface IFormErrors {
  [key: string]: Array<string>;
}

/**
 * The state for a given property. It holds any state needed for a given property.
 */
export interface IFormPropertyState {
  /** Has the field been focused on or manipulated. */
  isTouched: boolean;
  /** Is this field active or should it be ignored. */
  isActive: boolean;
}

/**
 * Value accepted by an HTML input's `value` attribute.
 */
export type InputValue = InputHTMLAttributes<HTMLInputElement>['value'];

/**
 * Sanitizes the form interface by making sure:
 *
 * 1. The key itself is a string
 * 2. The value is anything that is accepted by the `HTMLInputElement` as a value.
 *
 * Since the values of the classes are directly used in inputs,
 * we expected them to be of a certain type.
 *
 * There's unfortunately no way of knowing
 * exactly which properties are decorated using `validatable`,
 * but this is our best guess.
 */
export type DeriveFieldProperties<T> =
  T extends Record<string, unknown>
    ? {
        // Value is of certain type that is accepted by the input element.
        [Key in keyof T]: T[Key] extends InputValue
          ? // Key is a string (of course).
            Key extends string
            ? Key
            : unknown
          : unknown;
      }[keyof T]
    : // If `T` does not not extend `Record<string, unknown>` (a record type or interface)
      // then we cannot derive the field properties. This is an acceptable state as we have
      // cases where we wish to infer the type of a form mode in a generic way with `FormModel<any>`.
      unknown;

/**
 * Element that contains the property name, and its corresponding errors array.
 */
export interface IInvalidProperty<T> {
  /** The property name ('firstName', 'lastName', etc). */
  propertyName: keyof T;

  /**
   * List of error messages generated when validating this property.
   * @example
   * ```ts
   * // For example, if the property failed 2 out of 3 validations,
   * [
   *   'First name must be at least 4 characters long',
   *   'First name must contain only alphanumeric characters'
   * ]
   * ```
   */
  errors: Array<string>;
}

/**
 * Result of calling the `FormModel.validation()`.
 * Contains a list of invalid properties that have failed validation,
 * and their errors. (If any, otherwise `isValid: true`).
 * Primary use: Highlight error inputs, and render error messages below them.
 */
export interface IFormValidationResult<T> {
  /** Is the entire form valid? */
  isValid: boolean;

  /** List of properties that failed validation. */
  invalidProperties: Array<IInvalidProperty<T>>;

  /**
   * Any error messages generated when validating the form.
   *
   * IMPORTANT: Will only include error messages that apply to the form as a
   * whole. Property-specific error messages will be included in the members of
   * `invalidProperties`.
   */
  errorMessages: Array<string>;
}

/**
 * The validation object for an individual property. Multiple errors may be
 * found when validating a property.
 *
 * Not to be confused with {@link IValidationResult `IValidationResult`}, which
 * describes the result of a single validator function.
 */
export interface IPropertyValidationResult {
  /** Is the property valid? */
  isValid: boolean;

  /** List of errors that failed validation. */
  errors: Array<string>;
}

/**
 * Helper type that describes a form validation function for a specific
 * for interface.
 */
export type FormValidationFunction<T> = (
  currentValues: DTO<T>
) => IValidationResult;

/**
 * Describes a Form: a data structure that can store user-entered data for
 * a specified set of properties or fields.
 */
export interface IForm<T = any> {
  /**
   * Given a property, returns the validation options for this property.
   *
   * @param property - The property name.
   * @throws {@link InvalidArgumentError} - If called for a property that is not validatable.
   * @returns `IValidatableOptions`.
   */
  getFormFieldMetaDataFor(
    property: DeriveFieldProperties<T>
  ): IValidatableOptions;

  /**
   * Validates an individual property. In order to allow it to be computed it is
   * made into a getter a that takes two properties and returns the {@link IValidationResult}.
   * @param propertyKey - The property key used to access the property.
   * @param ignorePropertyState - Ignore the form state such as `isTouched` and simply validate
   * the value. This is used by the validate method that validates the entire form.
   * @returns A structure that contains an isValid attribute and a list of error strings
   * for a given property.
   * @throws When the property key doesn't exist on the validatables map or the
   * propertyStateMap.
   */
  validateProperty(
    propertyKey: DeriveFieldProperties<T>,
    ignorePropertyState?: boolean
  ): IPropertyValidationResult;

  /**
   * Validates the form.
   * Runs all validations on all fields to provide a detailed overview of
   * exactly which fields are failing and what validations.
   *
   * Use {@link isValid} for a boolean indicating form validity, which aborts
   * as soon as one invalid field is encountered, and there's no need for
   * error messages.
   *
   * @param property - Property to validate (optional).
   * @returns `IFormValidationResult` containing all errors for all invalid properties.
   */
  validate: IFormValidationResult<T>;

  /**
   * A method that resets all fields in the form. This is the default
   * implementation, it sets properties to some basic state. In most cases,
   * each form should have their own clear method so that default values can
   * be set.
   */
  clear(): void;

  /**
   * Gets the `isTouched` property off an existing validatable property.
   * @param property - The property name.
   * @returns If it is true it means that this form field is dirty and has been
   * manipulated.
   * @throws If the property in question doesn't exist on this `formModel`.
   */
  getTouch(property: DeriveFieldProperties<T>): boolean;

  /**
   * Set the property to touched. This means it is now should be validated.
   * The clear function sets all properties to have an `isTouched` value of false.
   * @param property - The string that represents the property so that it can be retrieved
   * from the `validatablesMap`.
   * @throws When the property given doesn't exist.
   */
  setTouch(property: DeriveFieldProperties<T>): void;

  /**
   * This is used to clear a single field. It clears the value and
   * then resets the field state.
   *
   * **NOTE:** It will NOT reactivate a deactivated field unless requested with
   * the `reactivate` param.
   *
   * @param property - The string that represents the property so that it can be retrieved
   * from the `validatablesMap`.
   * @param [reactivate] - If the property should be marked as active after
   * resetting it to its initial value. Defaults to `false`.
   *
   * @throws When the property given doesn't exist.
   */
  clearField(property: DeriveFieldProperties<T>, reactivate: boolean): void;

  /**
   * Generates the initial error object. This takes each field and gives it
   * an empty array.
   */
  initialErrorsObject: IFormErrors;

  /**
   * Indicates if all the fields match their validation rules.
   * Ignores `isTouched` and simply checks the values against their validation
   * rules.
   */
  isValid: boolean;

  /**
   * Indicates if all the properties in the form have been touched.
   * Useful for determining if a subform is ready to be validated.
   */
  allPropertiesTouched: boolean;
}

/**
 * ## **View examples for correct usage**!
 *
 * A standard for modeling and dealing with forms and their validation,
 * alongside {@link validatable}.
 *
 * ### For UI/UX (React).
 *
 * - @see `TextField` component for examples of pairing the `FormModel`
 * with the `TextField` component for the UI.
 * - 2nd example below shows how to use the `FormModel` with the basic HTML input element. Although that is not encouraged, it's there to show how the basics of how this approach works.
 *
 * *Examples below have a dot (`.`) before decorators
 * because JSDoc got confused*.
 *
 * @example
 * ```ts
 * // DTO.
 * interface ITestForm {
 *  firstName: string;
 *  lastName: string;
 * }
 *
 * // A non-observable, usual form. `FormModel` should always be extended.
 * class TestFormModel extends FormModel<ITestForm> {
 *   .@validatable({
 *     name: 'form.firstName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public firstName: string;
 *
 *   .@validatable({
 *     name: 'form.lastName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public lastName: string;
 *
 *   public constructor(dto: DTO<ITestForm>) {
 *     super(dto);
 *     this.firstName = dto.firstName;
 *     this.lastName = dto.lastName;
 *   }
 *
 *   public toDTO(): DTO<ITestForm> {
 *     return {
 *       firstName: this.firstName,
 *       lastName: this.lastName
 *     }
 *   }
 * }
 *
 * const form = new TestFormModel();
 * form.validate();
 * ```
 *
 * @example
 * ```tsx
 * interface ITestForm {
 *  firstName: string;
 *  lastName: string;
 * }
 *
 * // Observable form model (via mobx).
 * class TestFormModel extends FormModel<ITestForm> {
 *   .@observable
 *   .@validatable({
 *     name: 'form.firstName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public firstName: string;
 *
 *   .@observable
 *   .@validatable({
 *     name: 'form.lastName',
 *     validations: [minLength(2), maxLength(128)]
 *   })
 *   public lastName: string;
 *
 *   public constructor(dto: DTO<ITestForm>) {
 *     super(dto);
 *     this.firstName = dto.firstName;
 *     this.lastName = dto.lastName;
 *     // IMPORTANT to call this!
 *     makeObservable(this);
 *   }
 *
 *   public toDTO(): DTO<ITestForm> {
 *     return {
 *       firstName: this.firstName,
 *       lastName: this.lastName
 *     }
 *   }
 * }
 *
 * const Form: ReactComponent = observer(() => {
 *   // IMPORTANT to use useState! Otherwise the model gets replaced on
 *   // every render and state will reset every time.
 *   const [form] = useState(
 *     new TestFormModel({
 *       firstName: '',
 *       lastName: ''
 *     })
 *   );
 *
 *   const handleSubmit = () => {
 *     // Do something with results
 *     // Validation and errors are already handled by the Form component itself.
 *   }
 *   return (
 *     <Form form={form} submit={handleSubmit}>
 *       <FormFields />
 *     </Form>
 *   )
 * });
 *
 * const FormFields: ReactComponent = observer(() => {
 *   // This gets the formModel of the correct, supplied type.
 *   const { form, isLoading } = useFormContext(GenericFormModel);
 *
 *   return (
 *     <Form>
 *       <input value={form.firstName} onChange={(e) => form.firstName = e.target.value)} />
 *       <input value={form.lastName} onChange={(e) => form.lastName = e.target.value} />
 *       <button
 *         disabled={!form.isLoading}
 *         // Make sure the button is marked as a submit button.
 *         submit
 *       >
 *           Submit
 *         </button>
 *     </Form>
 *   )
 * });
 * ```
 */
export default abstract class FormModel<T>
  extends Model<DTO<T>>
  implements IForm<T>
{
  /**
   * This is the initial DTO after the constructors are run. It is nullable
   * because it is run in a setImmediate function and is not recognized as being
   * set in the constructor itself.
   * It is run in the setImmediate function so that it will be run after the child constructor
   * has finished and so the entire DTO has been shaped.
   */
  protected initialValues: Nullable<Map<keyof this, this[keyof this]>> = null;

  protected formValidationsBefore: Array<FormValidationFunction<T>> = [];
  protected formValidationsAfter: Array<FormValidationFunction<T>> = [];

  private readonly subformsMap: Map<DeriveFieldProperties<T>, ISubformMetadata>;

  /**
   * A map of all the validatable properties and their
   * respective validatable options.
   */
  private readonly validatablesMap: Map<
    DeriveFieldProperties<T>,
    IValidatableOptions
  >;

  @observable
  private readonly propertyStateMap: Map<
    DeriveFieldProperties<T>,
    IFormPropertyState
  > = new Map();

  /**
   * Set the property to active. This means it is now should be validated and return valid data.
   * @param property - The string that represents the property so that it can be retrieved
   * from the `validatablesMap`.
   * @throws When the property given doesn't exist.
   */
  @action
  protected activate(property: DeriveFieldProperties<T>): void {
    const propertyState = this.propertyStateMap.get(property);
    if (!propertyState) {
      throw new InvalidStateError(
        `No validation options for property ${property}`
      );
    }
    propertyState.isActive = true;
  }

  /**
   * Set the property to no longer be active. This means it should not be validated as an active property on the form.
   * @param property - The string that represents the property so that it can be retrieved
   * from the `validatablesMap`.
   * @throws When the property given doesn't exist.
   */
  @action
  protected deactivate(property: DeriveFieldProperties<T>): void {
    const propertyState = this.propertyStateMap.get(property);

    if (!propertyState) {
      throw new InvalidStateError(
        `No validation options for property ${property}`
      );
    }

    propertyState.isActive = false;
  }

  /**
   * `FormModel` constructor.
   *
   * An important constructor, actually. The {@link validatable} decorator
   * stores information about the validatable properties via `Reflect` API.
   * And that information is grabbed by this constructor in order to feed that
   * data into `validate()` and `isValid`.
   *
   * @param dto - `DTO` representing the state of the form.
   */
  public constructor(dto: DTO<T>) {
    super(dto);

    // Prototype is required to retrieve the metadata about the list of
    // validatable properties
    const prototype = Reflect.getPrototypeOf(this);
    if (prototype === null) {
      throw new InvalidStateError(`Prototype of a FormModel class is null.`);
    }

    if (prototype === FormModel.prototype) {
      throw new InvalidStateError(
        `FormModel should not be instantiated directly. Create and use a sub-class instead.`
      );
    }

    this.validatablesMap = Reflect.getMetadata(
      'validatable:map',
      prototype
    ) as typeof this.validatablesMap;

    this.validatablesMap?.forEach((item, key) => {
      this.propertyStateMap.set(key, { isTouched: false, isActive: true });
    });

    // Register subforms found in the metadata
    this.subformsMap =
      Reflect.getMetadata('subforms:map', prototype) ?? new Map();

    for (const subformKey of this.subformsMap.keys()) {
      this.propertyStateMap.set(subformKey, {
        isTouched: false,
        isActive: true
      });
    }

    // After the object is totally initialized, we can store the initial values for each
    // validatable property in the form. The `setImmediate` is used to ensure that the
    // constructor of the subclass has completed first and that any other synchronous
    // initialization has otherwise completed.
    setImmediate(() => {
      // Initialize the map. This will be null until this step, signaling to any other
      // consumers of the initial values that they should wait until this step has completed.
      this.initialValues = new Map();

      // Store the initial values for each validatable property based on what the field is
      // currently set to.
      this.validatablesMap?.forEach((options, property) => {
        this.initialValues?.set(
          property as keyof this,
          this[property as keyof this] as this[keyof this]
        );
      });

      for (const subformKey of this.subformsMap.keys()) {
        this.initialValues?.set(
          subformKey as keyof this,
          this[subformKey as keyof this] as this[keyof this]
        );
      }
    });
  }

  /** @inheritdoc */
  public getFormFieldMetaDataFor(
    property: DeriveFieldProperties<T>
  ): IValidatableOptions {
    // TODO: FIX
    const options = this.validatablesMap.get(property);
    if (!options) {
      throw new InvalidArgumentError(
        `${property as string} is not a validatable property on ${
          this.constructor.name
        }`
      );
    }

    return options;
  }

  /**
   * Given a property key that corresponds to a subform, retrieve said subform's
   * metadata.
   *
   * @param property - The key of the subform property.
   * @returns The subform's metadata, in {@link ISubformMetadata} form.
   * @throws An {@link InvalidArgumentError} if the specified key does not
   * represent a subform in this form.
   */
  protected getSubformMetadataFor(
    property: DeriveFieldProperties<T>
  ): ISubformMetadata {
    const metadata = this.subformsMap.get(property);

    if (!metadata) {
      throw new InvalidArgumentError(
        `${property as string} is not a subform property on ${
          this.constructor.name
        }`
      );
    }

    return metadata;
  }

  /** @inheritdoc */
  @computed
  public get validateProperty(): (
    propertyKey: DeriveFieldProperties<T>,
    ignorePropertyState?: boolean
  ) => IPropertyValidationResult {
    return (
      propertyKey: DeriveFieldProperties<T>,
      ignorePropertyState?: boolean
    ): IPropertyValidationResult => {
      const result: IPropertyValidationResult = {
        isValid: true,
        errors: []
      };

      const property = this[propertyKey as keyof this];
      const propertyState = this.propertyStateMap.get(propertyKey);

      if (!propertyState) {
        throw new InvalidStateError(
          `No state for property ${propertyKey as string}`
        );
      }

      if (!ignorePropertyState) {
        // Return the default result if the property is inactive or untouched.
        if (!propertyState.isActive || !propertyState.isTouched) {
          return result;
        }
      }

      // If the property is a sub-form...
      if (property instanceof FormModel) {
        // Attempt to validate it right away with its `validate` getter, but
        // only if it's required or its fields have been touched.
        const shouldValidate =
          !this.getSubformMetadataFor(propertyKey).optional ||
          property.anyPropertyTouched;

        if (shouldValidate) {
          const { isValid, errorMessages } = property.validate;
          return { isValid, errors: errorMessages };
        }

        // Skip validation if the above check is false. This would mean that
        // the property is optional AND none of its fields have been touched.
        return {
          isValid: true,
          errors: []
        };
      }

      // If the property is not a sub-form, get its validation options and
      // validate against its current value.
      const validationOptions = this.validatablesMap.get(propertyKey);

      if (!validationOptions) {
        throw new InvalidStateError(
          `No validation options for property ${propertyKey as string}`
        );
      }

      const value = this[propertyKey as keyof this];
      let errors = [];
      errors = runValidations(propertyKey as string, value, validationOptions);

      if (errors.length > 0) {
        result.isValid = false;
        result.errors = [...errors];
      }

      return result;
    };
  }

  /** @inheritdoc */
  @computed
  public get validate(): IFormValidationResult<T> {
    const result: IFormValidationResult<T> = {
      isValid: true,
      invalidProperties: [],
      errorMessages: []
    };

    const formDTO = this.toDTO();

    // First, run whole form validations in the "before" array.
    for (const validatorFn of this.formValidationsBefore) {
      const { isValid, errorMessage } = validatorFn(formDTO);

      if (!isValid) {
        result.isValid = false;

        if (errorMessage) {
          result.errorMessages.push(errorMessage);
        }
      }
    }

    // If there was an error in the validations before, don't even bother
    // running property validations.
    if (!result.isValid) {
      // But also add ALL properties as invalid so they get highlighted.
      for (const propertyKey of this.validatablesMap.keys()) {
        result.invalidProperties.push({
          propertyName: propertyKey as keyof T,
          errors: []
        });
      }

      return makeAutoObservable(result);
    }

    // Second, run property-specific validations. Subforms will also be
    // validated here since they are treated as properties of their parent form.
    const keysToCheck = [
      ...this.validatablesMap.keys(),
      ...this.subformsMap.keys()
    ];

    keysToCheck?.forEach((propertyKey) => {
      const propertyIsOptionalSubform =
        this[propertyKey as keyof this] instanceof FormModel &&
        !this.getSubformMetadataFor(propertyKey).optional;

      // TODO: Add a check for optional non-subform properties.
      const propertyIsOptionalField = false;

      const propertyIsRequired =
        !propertyIsOptionalField && !propertyIsOptionalSubform;

      // Set any required fields as touched so the form cannot be submitted
      // until they are deemed valid.
      //
      // Do not set any optional properties as touched. This doesn't necessarily
      // mean that they will skip validation, since they may have been marked as
      // touched by a UI Form component.
      if (propertyIsRequired) {
        this.setTouch(propertyKey);
      }

      // Validate every individual property that has been touched. This will
      // skip any non-touched, optional properties.
      const validation = this.validateProperty(propertyKey);
      const { errors } = validation;

      if (!validation.isValid) {
        result.isValid = false;

        result.invalidProperties.push({
          propertyName: propertyKey as keyof T,
          errors
        });
      }
    });

    // If there was an error in the validations before, don't run the next
    // set of validations.
    if (!result.isValid) {
      return makeAutoObservable(result);
    }

    // Last, run whole form validations in the "after" array.
    for (const validatorFn of this.formValidationsAfter) {
      const { isValid, errorMessage } = validatorFn(formDTO);

      if (!isValid) {
        result.isValid = false;

        if (errorMessage) {
          result.errorMessages.push(errorMessage);
        }
      }
    }

    if (!result.isValid) {
      // Add ALL properties as invalid so they get highlighted.
      for (const propertyKey of this.validatablesMap.keys()) {
        result.invalidProperties.push({
          propertyName: propertyKey as keyof T,
          errors: []
        });
      }
    }

    return makeAutoObservable(result);
  }

  /** @inheritdoc */
  @action
  public clear(): void {
    for (const key of this.propertyStateMap.keys()) {
      this.clearField(key, true);
    }
  }

  /** @inheritdoc */
  public getTouch(property: DeriveFieldProperties<T>): boolean {
    const propertyState = this.propertyStateMap.get(property);
    if (!propertyState) {
      throw new InvalidStateError(
        `No validation options for property ${property}`
      );
    }
    return propertyState.isTouched;
  }

  /** @inheritdoc */
  @action
  public setTouch(property: DeriveFieldProperties<T>): void {
    const propertyState = this.propertyStateMap.get(property);
    if (!propertyState) {
      throw new InvalidStateError(
        `No validation options for property ${property}`
      );
    }
    propertyState.isTouched = true;
  }

  /** @inheritdoc */
  @action
  public clearField(
    property: DeriveFieldProperties<T>,
    reactivate = false
  ): void {
    const propertyState = this.propertyStateMap.get(property);

    if (!propertyState) {
      throw new InvalidStateError(
        `No validation options for property ${property}`
      );
    }

    const propertyValue = this[property as keyof this];

    // If this property is a nested form, use its `clear` method.
    if (propertyValue instanceof FormModel) {
      propertyValue.clear();
    }
    // If not, reset it to its initial value.
    else if (this.initialValues?.has(property as keyof this)) {
      const initialValue = this.initialValues.get(property as keyof this);

      this[property as keyof this] = initialValue as this[keyof this];
    }

    // Set the property as untouched and reactivate it if requested.
    if (propertyState) {
      propertyState.isTouched = false;

      if (reactivate) {
        propertyState.isActive = true;
      }
    }
  }

  /** @inheritdoc */
  @computed
  public get initialErrorsObject(): IFormErrors {
    const initialErrorsObject: IFormErrors = {};
    const keys = this.validatablesMap.keys();

    for (const key of keys) {
      initialErrorsObject[key as string] = [];
    }

    return initialErrorsObject;
  }

  /** @inheritdoc */
  @computed
  public get isValid(): boolean {
    const formDTO = this.toDTO();

    // First, run whole form validations in the "before" array.
    for (const validatorFn of this.formValidationsBefore) {
      const { isValid } = validatorFn(this.toDTO());

      if (!isValid) {
        return false;
      }
    }

    // Second, run property-specific validations.
    const keys = this.validatablesMap.keys();

    for (const key of keys) {
      if (!this.validateProperty(key, true).isValid) {
        return false;
      }
    }

    // Last, run whole form validations in the "after" array.
    for (const validatorFn of this.formValidationsAfter) {
      const { isValid } = validatorFn(formDTO);

      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  /** @inheritdoc */
  @computed
  public get allPropertiesTouched(): boolean {
    for (const [key, value] of this.propertyStateMap.entries()) {
      if (!value.isTouched) {
        return false;
      }
    }

    return true;
  }

  /** @inheritdoc */
  @computed
  public get anyPropertyTouched(): boolean {
    for (const [key, value] of this.propertyStateMap.entries()) {
      if (value.isTouched) {
        return true;
      }
    }

    return false;
  }

  /**
   * Given a {@link FormValidationFunction validation function}, add said function
   * to this form's list of "before" validations. These validations will run
   * before property-specific validations.
   *
   * @param validatorFn - The validation function to add.
   */
  protected addValidationBefore(validatorFn: FormValidationFunction<T>): void {
    this.formValidationsBefore.push(validatorFn);
  }

  /**
   * Given a {@link FormValidationFunction validation function}, add said function
   * to this form's list of "after" validations. These validations will run
   * after property-specific validations.
   *
   * @param validatorFn - The validation function to add.
   */
  protected addValidationAfter(validatorFn: FormValidationFunction<T>): void {
    this.formValidationsAfter.push(validatorFn);
  }
}
