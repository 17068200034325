import { type IContent } from '@/services/isomorphic/ContentService';
import { FunctionComponent } from 'react';

import { RenderContent } from '../Content/RenderContent';

export interface IContentProps {
  /**
   * Content to render.
   */
  content: IContent;
}

/**
 * A component that abstracts over content rendering. It takes in content and renders
 * it as is.
 */
export const DirectContent: FunctionComponent<IContentProps> = ({
  content
}) => {
  return <RenderContent content={content} />;
};

export default DirectContent;
