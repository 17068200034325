'use client';

import type { FC } from 'react';

import {
  ApplePayProvider,
  BuyWithApplePayButton
} from '@/react/components/ApplePay';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { exhaustiveFallback } from '@/utils/function-utils';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { PayPalProvider } from '@/react/providers/integrations/PayPalProvider';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import { useExpressPaymentOptions } from './useExpressPaymentOptions';
import { ExpressCheckoutWithPayPalButton } from '../../PayPal/ExpressCheckoutWithPayPalButton';

import S from './styles.module.scss';
import { checkout_shipping_expressPaymentOptions } from "@/lang/__generated__/ahnu/checkout_shipping_expressPaymentOptions";
import { checkout_shipping_orContinueBelow } from "@/lang/__generated__/ahnu/checkout_shipping_orContinueBelow";

export interface IExpressPaymentOptionsProps {
  /** Which variant of the component to use. */
  variant?: 'checkout' | 'cart' | 'minicart';
}

/** The internal express payment options component. */
const InternalExpressPaymentOptions: FC<IExpressPaymentOptionsProps> = ({
  variant = 'cart'
}) => {
  const { cart } = useGlobalContext();
  const paymentOptions = useExpressPaymentOptions();

  if (paymentOptions.length === 0) return null;

  return (
    <div className={classes(S.container, S[variant])}>
      <h2 className={S.title}>
        {msg(checkout_shipping_expressPaymentOptions)}
      </h2>

      <ul className={S.list}>
        {paymentOptions.map((method) => {
          switch (method) {
            case 'ApplePay':
              return (
                <li className={S.listItem} key={method}>
                  <BuyWithApplePayButton
                    cart={cart}
                    theme={variant === 'cart' ? 'white-outline' : undefined}
                  />
                </li>
              );
            case 'PayPal':
              return (
                <li className={S.listItem} key={method}>
                  <ExpressCheckoutWithPayPalButton cart={cart} />
                </li>
              );
            default:
              return exhaustiveFallback(method, () => {
                LoggerService.error(
                  new Error(`Unknown express payment method: "${method}".`)
                );
                return null;
              });
          }
        })}
      </ul>

      <span className={S.subtitle}>
        {msg(checkout_shipping_orContinueBelow)}
      </span>
    </div>
  );
};

/**
 * The `ExpressPaymentOptions` will display the available express payment
 * like Apple Pay, PayPal, Google Pay, etc. The user can select one of these options
 * to pay for an order.
 */
export const ExpressPaymentOptions: FC<IExpressPaymentOptionsProps> = (
  props
) => {
  return (
    // If there is a rendering error, just don't show anything.
    <CommonErrorBoundary fallback={null}>
      <PayPalProvider>
        <ApplePayProvider>
          <InternalExpressPaymentOptions {...props} />
        </ApplePayProvider>
      </PayPalProvider>
    </CommonErrorBoundary>
  );
};
