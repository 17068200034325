'use client';

import { FunctionComponent } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { useLocale } from '@/react/hooks/useLocale';
import { Link } from '../../../core-ui/Link';

import S from './styles.base.module.scss';
import { footer_bottomLink } from "@/lang/__generated__/ahnu/footer_bottomLink";

/**
 * The bottom promo link in the footer.
 */
export const BottomPromoLink: FunctionComponent = () => {
  const [msg] = useLocaleMessage();
  const { language, country } = useLocale();

  const showBottomPromoLink = ConfigurationService.getConfig(
    'footer',
    language,
    country
  ).getSetting('showBottomPromoLink').value;

  if (!showBottomPromoLink) {
    return null;
  }

  return (
    <div className={S.bottomLink}>
      <Link className={S.link} href="/guarantee" variant="text-underline">
        {msg(footer_bottomLink)}
      </Link>
    </div>
  );
};
