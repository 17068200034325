import type { DTO } from '@/type-utils';
import { v4 as uuid } from 'uuid';
import { Currency, MoneyModel } from '../../Money';
import type ICart from '../ICart';
import MOCK_PRODUCT_LINE_ITEM from './MOCK_PRODUCT_LINE_ITEM';

const MOCK_CART_DTO: DTO<ICart> = {
  uuid: uuid(),
  ownerID: '3e130a60-3c0c-4852-a23f-77884f22bae9',
  promotions: [],
  coupons: [],
  items: [MOCK_PRODUCT_LINE_ITEM],
  cartDiscount: {
    amount: '0',
    currency: Currency.USD
  },
  linesDiscount: {
    amount: '0',
    currency: Currency.USD
  },
  discount: {
    amount: '0',
    currency: Currency.USD
  },
  tax: {
    amount: '10',
    currency: Currency.USD
  },
  total: {
    amount: '120',
    currency: Currency.USD
  },
  subtotal: {
    amount: '100',
    currency: Currency.USD
  },
  shippingCost: {
    amount: '10',
    currency: Currency.USD
  },
  selectedShippingMethod: null,
  shipToAddress: null,
  giftCards: [
    {
      amountApplied: MoneyModel.fromAmount(5),
      balance: MoneyModel.fromAmount(15),
      cvd: '123',
      giftCardNumber: '1234567890123456',
      id: '1234567890123456'
    }
  ]
};

export default MOCK_CART_DTO;
