'use client';

import {
  FunctionComponent,
  Suspense,
  useCallback,
  useEffect,
  useState
} from 'react';
import { msgf } from '@/services/isomorphic/I18NService';
import CookieService from '@/services/isomorphic/CookieService';
import { ENDLESS_AISLE_COOKIE_NAME } from '@/configs/env/public';
import { Nullable } from '@/type-utils';
import { CookieModel } from '@/services/models/Cookie';
import { Banner } from '../../utility/Banner';

import S from './styles.module.scss';
import { header_endlessAislePOSBanner_message } from "@/lang/__generated__/ahnu/header_endlessAislePOSBanner_message";

/** POS banner that displays above or below the header when the Magtek / Endless Isle POS is enabled. */
export const POSStoreBanner: FunctionComponent = () => {
  // Check to see if the Magtek / Endless Aisle cookie is set.
  // If so, enable the Magtek / Endless Aisle POS Store banner and
  // use the cookie value as the store ID in the banner message.
  const [storeId, setStoreId] = useState<string>('');
  const [isPOSStoreEnabled, setIsPOSStoreEnabled] = useState<boolean>(false);

  useEffect(() => {
    const cookie: Nullable<CookieModel> = CookieService.tryGet(
      ENDLESS_AISLE_COOKIE_NAME
    );
    setStoreId(String(cookie?.value));
    if (cookie?.value) {
      setIsPOSStoreEnabled(true);
    }
  }, []);

  return (
    <Suspense>
      {isPOSStoreEnabled && (
        <Banner className={S.posStoreBanner} closable={false}>
          <span>
            {msgf(header_endlessAislePOSBanner_message, {
              storeId
            })}
          </span>
        </Banner>
      )}
    </Suspense>
  );
};
