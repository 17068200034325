'use client';

import { useIsApplePayAvailable } from '@/react/components/ApplePay';
import type { ExpressPaymentMethod } from './ExpressPaymentMethod';
import { useIsPayPalAvailable } from '../../PayPal/hooks/useIsPayPalAvailable';

/**
 * A hook that provides the express payment options.
 * @returns The express payment options.
 */
export const useExpressPaymentOptions = (): Array<ExpressPaymentMethod> => {
  const paymentMethods: Array<ExpressPaymentMethod> = [];

  const isApplePayAvailable = useIsApplePayAvailable();

  if (isApplePayAvailable) {
    paymentMethods.push('ApplePay');
  }

  const { expressCheckoutAvailable: isPayPalExpressCheckoutAvailable } =
    useIsPayPalAvailable();

  if (isPayPalExpressCheckoutAvailable) {
    paymentMethods.push('PayPal');
  }

  return paymentMethods;
};
