import type { FC } from 'react';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Link } from '@/react/components/core-ui/Link';
import { useMobileDrawer } from '../../../useMobileDrawer';

import { MobileNavItem } from '../../MobileNavItem';
import type { ICategoryLinkProps } from './ICategoryLinkProps';
import S from '../../../styles.base.module.scss';

/**
 * A link to a category page. Provides the interaction details appropriate linking.
 */
export const CategoryLink: FC<ICategoryLinkProps> = ({ category }) => {
  const { closeDrawer } = useMobileDrawer();

  return (
    <MobileNavItem>
      <Link
        className={S.navLink}
        href={`/c${category.href}`}
        onClick={closeDrawer}
        interactionDetails={{
          action: EventType.NavigationLink,
          link: {
            linkName: category.label,
            linkCategory: 'top-navigation',
            linkSection: category.label
          }
        }}
      >
        {category.label}
      </Link>
    </MobileNavItem>
  );
};
