import { Currency, IMoney } from '../../Money';
import { IPrice } from '../../Price';
import TaxClass from '../../TaxClass';
import { IProductLineItem, LineItemType } from '../LineItem';

const MOCK_PRODUCT_LINE_ITEM: IProductLineItem = {
  uuid: '0012ea21-0af8-4a95-9457-10aa6adb823b',
  sku: '1166670-WWH-07',
  styleNumber: '1166670',
  upc: '197634237862',
  name: 'Sequence 1 Low',
  quantity: 2,
  taxRate: 0.1,
  primaryCategory: '',

  unitPrice: {
    retailPrice: {
      amount: '50',
      currency: Currency.USD
    } as IMoney
  } as IPrice,

  subtotal: {
    amount: '100',
    currency: Currency.USD
  },

  netTotal: {
    amount: '100',
    currency: Currency.USD
  },

  tax: {
    amount: '10',
    currency: Currency.USD
  },

  total: {
    amount: '110',
    currency: Currency.USD
  },

  promotions: [],

  cartID: '',
  type: LineItemType.Product,
  taxClass: TaxClass.ShoesAndLaces
};

export default MOCK_PRODUCT_LINE_ITEM;
