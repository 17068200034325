'use client';

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Icon, IconTypes } from '../../core-ui/Icon';
import IStylable from '../../traits/IStylable';

import S from './styles.module.scss';

export interface IBannerProps extends PropsWithChildren, IStylable {
  /** If `true`, the banner will have a close button on its rightmost side. */
  closable?: boolean;

  /**
   * If the banner should be open (visible) or not.
   * Use this prop if you want to control visibility from the outside.
   */
  open?: boolean;
}

/** Spans the full width of its container and displays important information. */
export const Banner: FunctionComponent<IBannerProps> = ({
  closable = true,
  open = true,
  id,
  className,
  style,
  children
}) => {
  const [visible, setVisible] = useState<boolean>(open);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <div
      id={id}
      style={style}
      className={classes(S.banner, className, {
        [S.closed]: !visible
      })}
    >
      {children}

      {/* CLose Icon */}
      {closable && (
        <div className={S.closeIconContainer}>
          <Icon
            className={S.closeIcon}
            icon={IconTypes.Close}
            onClick={() => setVisible(false)}
          />
        </div>
      )}
    </div>
  );
};
