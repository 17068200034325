'use client';

import { observer } from 'mobx-react-lite';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import { Suspense, useCallback, useState } from 'react';

import { useProductVM } from '@/react/hooks/useProductVM';
import CartVM from '@/react/view-models/CartVM';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { IProduct } from '@/services/models/Product';
import { Nullable } from '@/type-utils';
import { Button } from '../../core-ui/Button';
import { Link } from '../../core-ui/Link';
import { Modal } from '../../core-ui/Modal';
import { ModalHeader } from '../../core-ui/Modal/ModalHeader';
import { ProductDetailsPanel } from '../../product/ProductDetailsPanel';
import ProductDetailsVariant from '../../product/ProductDetailsVariant';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { CartLineItemSummaryList } from '../LineItemSummary';

import { ErrorAlertProvider } from '../../core-ui/ErrorAlert/ErrorAlertProvider';
import { MiniCartErrorAlert } from './MiniCartErrorAlert';
import { ExpressPaymentOptions } from '../../checkout/ExpressPaymentOptions';

import S from './styles.base.module.scss';
import { product_tiles_quickView_title } from "@/lang/__generated__/ahnu/product_tiles_quickView_title";
import { cart_cartDrawer_yourBasket } from "@/lang/__generated__/ahnu/cart_cartDrawer_yourBasket";
import { cart_cartDrawer_viewBasket } from "@/lang/__generated__/ahnu/cart_cartDrawer_viewBasket";
import { order_orderSummary_estimatedTotal } from "@/lang/__generated__/ahnu/order_orderSummary_estimatedTotal";
import { general_checkout } from "@/lang/__generated__/ahnu/general_checkout";

export interface IMiniCartProps {
  /**
   * The cart view model to display.
   */
  cart: CartVM;
}

/**
 * Mini Cart is a drop down that appears on hover of the cart icon in
 * the desktop header.
 */
export const MiniCart = observer(function MiniCart({ cart }: IMiniCartProps) {
  const [msg, msgf] = useLocaleMessage();
  const router = useRouter();

  const handleNavigateToCheckout = useCallback(
    () => router.push('/checkout'),
    [router]
  );

  // Edit product modal state.
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleModalClose = useCallback(() => setModalOpen(false), []);
  const [editProduct, setEditProduct] = useState<Nullable<IProduct>>();
  const productVM = useProductVM(editProduct);

  const onClickEdit = useCallback((product: IProduct) => {
    setEditProduct(product);
    setModalOpen(true);
  }, []);

  return (
    <ErrorAlertProvider>
      <div className={S.container}>
        {productVM ? (
          <Modal
            isOpen={modalOpen}
            onClose={handleModalClose}
            className={S.editModal}
          >
            <ModalHeader onClose={handleModalClose} className={S.modalHeader}>
              {msg(product_tiles_quickView_title)}
            </ModalHeader>
            <div className={S.modalBody}>
              <ProductDetailsPanel
                product={productVM}
                productToUpdate={editProduct!}
                variant={ProductDetailsVariant.Update}
                onAddToCart={handleModalClose}
              />
            </div>
          </Modal>
        ) : null}

        <div className={S.cart}>
          <div className={S.cartHeader}>
            <div className={S.yourCart}>
              {msg(cart_cartDrawer_yourBasket)}
            </div>
            <Link variant="text" href="/cart" className={S.viewBasket}>
              {msg(cart_cartDrawer_viewBasket)}
            </Link>
          </div>

          <Suspense>
            <MiniCartErrorAlert cart={cart} />
          </Suspense>

          <div className={S.productList}>
            <CartLineItemSummaryList
              cart={cart}
              onClickEdit={onClickEdit}
              direction="column"
            />
          </div>

          <div className={S.cartFooter}>
            <div className={S.totalContainer}>
              <div className={S.total}>
                {msg(order_orderSummary_estimatedTotal)}
              </div>
              {!cart.total.pending ? (
                <div className={S.totalAmount}>
                  {cart.total.value.toString()}
                </div>
              ) : (
                <SkeletonLoader className={S.skeletonLoader} />
              )}
            </div>

            <div className={S.paymentButtons}>
              <Button fullWidth onClick={handleNavigateToCheckout}>
                {msg(general_checkout)}
              </Button>

              <ExpressPaymentOptions variant="minicart" />
            </div>
          </div>
        </div>
      </div>
    </ErrorAlertProvider>
  );
});
