import { FunctionComponent } from 'react';

import type { ICoreMediaContent } from '@/services/isomorphic/ContentService';
import { GenericContent } from '@/react/components/cms-content';
import { CMSPlacementTypes, ContentModel } from '@deckers/services/src/models/Content';

export interface IBuilderRenderProps {
  /**
   * The content already fetched from CoreMedia.
   */
  content: ICoreMediaContent;
}

/**
 * Renders content that is specifically for CoreMedia. This should be preprepared and verified
 * to be in the correct shape.
 */
export const CoreMediaRender: FunctionComponent<IBuilderRenderProps> = ({ content }) => {
  const contentModel = content.data ? ContentModel.from(content.data) : null;
  const main = contentModel?.getPlacement(CMSPlacementTypes.Main);

  return (
    <>
      {main && <GenericContent placement={main} />}
    </>
  );
};

export default CoreMediaRender;
