'use client';

import { useCallback, useMemo, useState } from 'react';
import type { IErrorAlertContext } from './IErrorAlertContext';

/**
 * `useErrorAlertProviderValue` is a custom hook that abstracts all necessary logic to manage and dismiss error
 * alerts in our app. It returns a context value for use with `ErrorAlertContext.Provider`.
 * @returns An Error Alert provider value.
 */
export const useErrorAlertProviderValue = (): IErrorAlertContext => {
  const [error, setError] = useState<unknown>(null);

  const alert = useCallback((error: unknown) => {
    setError(error);
  }, []);

  const closeAlert = useCallback(() => {
    setError(null);
  }, []);

  const value = useMemo((): IErrorAlertContext => {
    return { error, alert, closeAlert };
  }, [error, alert, closeAlert]);

  return value;
};
