'use client';

import { FunctionComponent } from 'react';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Link } from '@/react/components/core-ui/Link';
import { INavigationLinkProps } from './INavigationLinkProps';
import { useMobileDrawer } from '../../useMobileDrawer';

import S from '../../styles.base.module.scss';

/**
 * Navigation item for use when specifying nav structure via config.
 */
export const NavigationLink: FunctionComponent<INavigationLinkProps> = ({
  item
}) => {
  const { closeAll } = useMobileDrawer();

  return (
    <Link
      className={S.navLink}
      href={`${item.href}`}
      onClick={closeAll}
      interactionDetails={{
        action: EventType.NavigationLink,
        link: {
          linkName: item.label,
          linkCategory: 'top-navigation',
          linkSection: item.label
        }
      }}
    >
      {item.label}
    </Link>
  );
};
