'use client';

import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

/**
 * Scroll interface. Contains x and y positions.
 */
interface IScrollPosition {
  /**
   * X position (horizontal).
   */
  scrollX: number;
  /**
   * Y position (vertical).
   */
  scrollY: number;
  /**
   * Delta X position (horizontal) - difference between current and previous scroll position X.
   * If the user scrolls to the right, deltaX will be positive.
   * If the user scrolls to the left, deltaX will be negative.
   */
  deltaX: number;
  /**
   * Delta Y position (vertical) - difference between current and previous scroll position Y
   * If the user scrolls down, deltaY will be positive.
   * If the user scrolls up, deltaY will be negative.
   */
  deltaY: number;
}

/**
 * Custom hook that returns the current scroll position and the difference between
 * the current and previous scroll position.
 * @param delay - The delay in milliseconds before the scroll position is updated.
 * @returns Returns an object with the current scroll position and deltas.
 */
export function useScroll(delay: number = 20): IScrollPosition {
  const [scrollPosition, setScrollPosition] = useState<IScrollPosition>({
    scrollX: 0,
    scrollY: 0,
    deltaX: 0,
    deltaY: 0
  });
  let deltaX = 0;
  let deltaY = 0;

  useEffect(() => {
    /**
     * Handles scroll event and updates scroll position; debounce the scroll
     * event to prevent performance issues.
     *
     * The function is enclosed in a useEffect hook to prevent the function from
     * being recreated on every render. This allows the function to be properly
     * removed from the event listener once the component is unmounted.
     */
    const handleScroll = debounce((): void => {
      const { scrollX, scrollY } = window;
      setScrollPosition({
        scrollX,
        scrollY,
        deltaX: Math.floor(deltaX - scrollX),
        deltaY: Math.floor(deltaY - scrollY)
      });
      deltaX = scrollX - scrollPosition.scrollX;
      deltaY = scrollY - scrollPosition.scrollY;
    }, delay);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return scrollPosition;
}
