'use client';

import { FC, PropsWithChildren } from 'react';
import { MobileDrawerProvider } from '../../useMobileDrawer';

/**
 * Indicates that any Drawer component that occurs after this component
 * (and consequently any use of `useMobileDrawer`) points to a new Drawer state.
 *
 * **Note:** This component allows for nested drawers to use the same useMobileDrawer hook
 * to manage drawer state without having to provide multiple Drawer providers for every
 * nested drawer.
 *
 * Replaces the current drawer context with a new drawer context.
 * @see useMobileDrawer
 * @see MobileDrawerProvider
 */
export const MobileNestedDrawers: FC<PropsWithChildren> = ({ children }) => {
  return <MobileDrawerProvider>{children}</MobileDrawerProvider>;
};
