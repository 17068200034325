'use client';

import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { maybe } from '@/utils/null-utils';
import { FunctionComponent, useMemo } from 'react';
import { Icon, IconSizeProp, IconTypes } from '../core-ui/Icon';
import { Link } from '../core-ui/Link';
import { ISocialItem, SocialName } from './ISocialsProps';

import S from './styles.base.module.scss';

// I'm not sure why TypeScript could not accept
// icons: Record<string, IconDefinition>.
const icons = {
  [SocialName.Instagram]: IconTypes.Instagram,
  [SocialName.Twitter]: IconTypes.Twitter,
  [SocialName.Facebook]: IconTypes.Facebook,
  [SocialName.Youtube]: IconTypes.YouTube,
  [SocialName.Pinterest]: IconTypes.Pinterest,
  [SocialName.Blog]: IconTypes.Blog,
  [SocialName.Strava]: IconTypes.Strava,
  [SocialName.TikTok]: IconTypes.TikTok
};

interface ISocialItemProps {
  /**
   * The social item. It's an object with the social media name, link, title, and className.
   */
  item: ISocialItem;

  /**
   * If true, the link will open in a new tab.
   */
  openInNewTab?: boolean;
}

/**
 * The `SocialItem` component displays a single social media icon and link.
 */
const SocialItem: FunctionComponent<ISocialItemProps> = ({
  item,
  openInNewTab = true
}) => {
  // Check for the brand and set the social icon sizes accordingly.
  const SocialIconType = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeXS,
      AHNU: IconSizeProp.SizeXS
    }),
    []
  );

  const { name, link, title } = item;

  return (
    <li className={S.item}>
      <Link
        variant="base"
        href={link}
        title={title}
        className={S.link}
        openInNewTab={openInNewTab}
      >
        <Icon
          className={item.className}
          // handle the case where the icon is not found by name due to bad data
          icon={maybe(icons[name]) ?? IconTypes.QuestionCircle}
          size={SocialIconType}
        />
      </Link>
    </li>
  );
};

/**
 * A row of social media icons and links.
 */
const Socials: FunctionComponent = () => {
  const socialsConfig = ConfigurationService.getConfig('socials');

  const socials = useMemo<Array<ISocialItem>>(() => {
    return [
      {
        name: SocialName.Blog,
        title: 'Blog',
        link: socialsConfig.getSetting('blog').value,
        className: S.blog
      },
      {
        name: SocialName.Facebook,
        title: 'Facebook',
        link: socialsConfig.getSetting('facebook').value,
        className: S.facebook
      },
      {
        name: SocialName.Instagram,
        title: 'Instagram',
        link: socialsConfig.getSetting('instagram').value,
        className: S.instagram
      },
      {
        name: SocialName.Pinterest,
        title: 'Pinterest',
        link: socialsConfig.getSetting('pinterest').value,
        className: S.pinterest
      },
      {
        name: SocialName.Twitter,
        title: 'Twitter',
        link: socialsConfig.getSetting('twitter').value,
        className: S.twitter
      },
      {
        name: SocialName.Youtube,
        title: 'Youtube',
        link: socialsConfig.getSetting('youtube').value,
        className: S.youtube
      },
      {
        name: SocialName.Strava,
        title: 'Strava',
        link: socialsConfig.getSetting('strava').value,
        className: S.strava
      },
      {
        name: SocialName.TikTok,
        title: 'TikTok',
        link: socialsConfig.getSetting('tiktok').value,
        className: S.tiktok
      }
    ];
  }, [socialsConfig]);

  return (
    <div className={S.listContainer}>
      <ul className={S.list}>
        {socials.map((item) => {
          if (item.link === '') return null;
          return (
            <SocialItem
              key={item.link}
              item={item}
              openInNewTab={item.name !== 'blog'}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Socials;
