import { Nullable } from '@/type-utils';
import { NotImplementedError } from '@/utils/errors';
import 'reflect-metadata';

/**
 * A validator function.
 */
export type Validation<T = unknown> = (
  name: string,
  property: string,
  value: T
) => IValidationResult;

/**
 * Result of a single call of {@link Validation}.
 */
export interface IValidationResult {
  /** Whether the value is valid. */
  isValid: boolean;

  /** The error message. Present if `isValid == false`. */
  errorMessage: Nullable<string>;
}

/** Validation options for a validatable property. */
export interface IValidatableOptions<T = unknown> {
  /** A path to a human-readable name resource string. */
  name: string;

  /**
   * Indicates whether or not this value is optional.
   * If so, empty input will not be validated, even if `nonEmpty`
   * validation is present.
   * Default is `false`.
   *
   * Note: This flag doesn't mean that passing validation is optional,
   * it means that the field itself is optional. So either it has to be
   * empty, or pass or validations. If it's present, it still HAS to be valid.
   */
  optional?: boolean;

  /** An array of validator functions. */
  validations: Array<Validation<T>>;
}

/**
 * Runs validations and returns error messages.
 * An empty array means no errors. (Valid value).
 *
 * @param property - Used for errors generated by the validators.
 * @param value - The value to validate.
 * @param validationOptions - Validation options object.
 * @returns Array of error messages.
 */
export const runValidations = <T>(
  property: string,
  value: T,
  validationOptions: IValidatableOptions<T>
): Array<string> => {
  const errors: Array<string> = [];
  // Don't validate empty input, if it's optional to have.
  if (validationOptions.optional === true && String(value).length === 0) {
    return errors;
  }

  validationOptions.validations.forEach((validation) => {
    const results = validation(validationOptions.name, property, value);

    if (!results.isValid && results.errorMessage) {
      errors.push(results.errorMessage);
    }
  });
  return errors;
};

/**
 * Returns whether or not a value passes all validation tests.
 * @param key - Property key.
 * @param value - The value.
 * @param validationOptions - The validation rules.
 * @returns `Boolean`.
 */
export const isValueValid = <T>(
  key: string,
  value: T,
  validationOptions: IValidatableOptions<T>
): boolean => {
  if (validationOptions.optional === true && String(value).length === 0) {
    return true;
  }

  for (const validation of validationOptions.validations) {
    const result = validation(validationOptions.name, key, value);
    if (!result.isValid) {
      return false;
    }
  }

  return true;
};

/**
 * Validatable property decorator. Marking a property using this decorator,
 * registers it as a validatable property, and becomes part of the form validity
 * via `FormModel.isValid` and `FormModel.validate()`.
 *
 * @param options - {@link IValidatableOptions}.
 * @returns A function that will inject decoration functionality
 * onto the class prototype, for the given property.
 */
export function validatable<T extends object, K extends keyof T & string>(
  options: IValidatableOptions<T[K]>
) {
  return function _(
    target: T,
    propertyKey: K,
    descriptor?: TypedPropertyDescriptor<T[K]>
  ): void {
    if (descriptor?.writable === false) {
      throw new NotImplementedError(
        `Defining a readonly validatable property does not make sense.`
        // Or does it?
      );
    }

    Reflect.defineProperty(target, propertyKey, {
      value: descriptor?.value,
      writable: true,
      enumerable: true
    });

    // Define a metadata property on the prototype (target)
    if (!Reflect.hasMetadata('validatable:map', target)) {
      Reflect.defineMetadata('validatable:map', new Map(), target);
    }

    const map: Map<string, IValidatableOptions<T[K]>> = Reflect.getMetadata(
      'validatable:map',
      target
    );
    map.set(propertyKey, options);
  };
}
