'use client';

import { FormModel, IForm } from '@/react/view-models/Form';
import { Constructor } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { useContext } from 'react';
import {
  FormContext,
  IFormContext
} from '../components/core-ui/Form/FormContext';

/**
 * The form context but with the type of the form type guarded so
 * that the fields component can know the actual type of the form.
 */
export interface IFormContextWithTypeGuard<T extends IForm>
  extends Omit<IFormContext, 'form'> {
  /**
   * The form model associated with this form components. It gets passed
   * into the context and shouldn't ever be set again.
   */
  form: T;
}

/**
 * UseFormContext hook contains gets the form context and makes sure it exists and is of the
 * right type.
 * @param formType - The class of the FormModel that should come back from the context.
 * @returns The form context.
 * @throws When used outside a FormProvider or the {@link FormModel} on the context
 * is of the wrong type.
 */
export const useFormContext = <FormType extends IForm>(
  formType: Constructor<FormType>
): IFormContextWithTypeGuard<FormType> => {
  const context = useContext(FormContext);

  if (!context || !(context.form instanceof formType)) {
    throw new InvalidStateError(
      `The fields must be wrapped by a Form component with a form model of type ${formType.name}.`
    );
  }

  return {
    ...context,
    form: context.form
  };
};
