'use client';

import {
  FunctionComponent,
  ReactElement,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { CSSTransition } from 'react-transition-group';
import { msg } from '@/services/isomorphic/I18NService';
import { SearchBox } from '../../../../Search';
import {
  Breakpoints,
  Breakpoint,
  Default
} from '../../../../core-ui/Breakpoints';
import { Icon, IconSizeProp, IconTypes } from '../../../../core-ui/Icon';

import S from './styles.base.module.scss';

import { ISearchProps } from './ISearchProps';
import { general_closeSearch } from "@/lang/__generated__/ahnu/general_closeSearch";
import { general_search } from "@/lang/__generated__/ahnu/general_search";

/**
 * Search button used in Header, next to searchForm.
 */
const Search: FunctionComponent<ISearchProps> = ({
  isInlineToggleable = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggle = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  // Search icon type depends on brand
  const SearchIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Search,
      AHNU: IconTypes.SearchLight
    }),
    []
  );

  // Close icon type depends on brand
  const CloseIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Close,
      AHNU: IconTypes.CloseLight
    }),
    []
  );

  // Define button toggle element used in both mobile and
  // 'inline toggleable' desktop versions
  const buttonToggle = isExpanded ? (
    <button
      type="button"
      className={`${S.buttonWithIcon} ${S.buttonAnimatedEntry}`}
      onClick={toggle}
      aria-label={msg(general_closeSearch)}
    >
      <Icon icon={CloseIconType} />
    </button>
  ) : (
    <button
      type="button"
      className={S.buttonWithIcon}
      onClick={toggle}
      aria-label={msg(general_search)}
    >
      <Icon icon={SearchIconType} size={IconSizeProp.SizeLG} />
    </button>
  );

  // Define search box element used in both mobile and
  // 'inline toggleable' desktop versions
  const searchBoxElement = (
    wrapperClass: string = S.searchWrapper,
    enterDoneClass: string = S.enterDone
  ): ReactElement => {
    return (
      <CSSTransition
        in={isExpanded}
        timeout={{
          enter: 0,
          exit: 300
        }}
        classNames={{
          enterActive: S.enterActive,
          enterDone: enterDoneClass,
          exit: enterDoneClass, // Keep it visible during exit
          exitActive: S.exitActive
        }}
      >
        <div className={wrapperClass}>
          <SearchBox setExpanded={setIsExpanded} inputRef={inputRef} />
        </div>
      </CSSTransition>
    );
  };

  // Focus on the input once it slides down. This is a good idea, right?
  useEffect(() => {
    if (!isExpanded) {
      return () => {};
    }

    const timeoutId = setTimeout(() => inputRef?.current?.focus(), 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isExpanded]);

  return (
    <Breakpoints>
      <Breakpoint media="desktop">
        {isInlineToggleable ? (
          <>
            {searchBoxElement(S.inlineSearchWrapper, S.inlineEnterDone)}
            {buttonToggle}
          </>
        ) : (
          <SearchBox setExpanded={setIsExpanded} inputRef={inputRef} />
        )}
      </Breakpoint>
      <Default>
        {buttonToggle}
        {searchBoxElement()}
      </Default>
    </Breakpoints>
  );
};

export default Search;
