'use client';

import { FunctionComponent } from 'react';
import { Link } from '@/react/components/core-ui/Link';
import { msg } from '@/services/isomorphic/I18NService';
import { useLogout } from '@/react/hooks/useLogout';
import { Icon, IconTypes, IconSizeProp } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { NavigationLink } from '../NavigationLink';
import { AccountUtilityLink } from './AccountUtilityLink';
import { useMobileDrawer } from '../../useMobileDrawer';

import S from '../../styles.base.module.scss';
import { MobileNavItem } from '../MobileNavItem';
import { MobileNestedDrawers } from '../MobileNestedDrawers';
import { ChooseCountryUtilityLink } from './ChooseCountryUtilityLink';
import { header_utilBar_storeLocator } from "@/lang/__generated__/ahnu/header_utilBar_storeLocator";
import { account_authenticated_logout } from "@/lang/__generated__/ahnu/account_authenticated_logout";

/**
 * Utility links that include: My Account, Store Locator, and Country Selector links. Used in the mobile navigation drawer.
 */
export const MobileUtilityLinks: FunctionComponent = () => {
  const { isLoggedIn, logOut } = useLogout();
  const { closeAll } = useMobileDrawer();
  const storeLocatorLabel = msg(header_utilBar_storeLocator);

  return (
    <>
      <MobileNestedDrawers key="nested-drawers-account">
        <AccountUtilityLink isLoggedIn={isLoggedIn} />
      </MobileNestedDrawers>
      <MobileNavItem>
        <span className={classes(S.navLink, S.labelContainer, S.utilityLink)}>
          <Icon icon={IconTypes.MapMarker} size={IconSizeProp.Size1X} />
          <Link
            className={S.utilityLink}
            href="/storelocator"
            onClick={closeAll}
            interactionDetails={{
              action: EventType.NavigationLink,
              link: {
                linkName: storeLocatorLabel,
                linkCategory: 'top-navigation',
                linkSection: storeLocatorLabel
              }
            }}
          >
            {storeLocatorLabel}
          </Link>
        </span>
      </MobileNavItem>
      {isLoggedIn && (
        <MobileNavItem>
          <button
            type="button"
            className={S.navLink}
            onClick={logOut}
            aria-label={msg(account_authenticated_logout)}
          >
            <span className={classes(S.labelContainer)}>
              <Icon icon={IconTypes.AngleLeft} size={IconSizeProp.SizeXS} />
              {msg(account_authenticated_logout)}
            </span>
          </button>
        </MobileNavItem>
      )}
      <MobileNestedDrawers key="nested-drawers-country">
        <ChooseCountryUtilityLink />
      </MobileNestedDrawers>
    </>
  );
};

export default MobileUtilityLinks;
