'use client';

import { FunctionComponent, Suspense, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withEcommerceEnabled } from '@/react/components/utility/EcommerceEnabled';

import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { Icon, IconSizeProp, IconTypes } from '../../../core-ui/Icon';
import { Link } from '../../../core-ui/Link';

import S from './styles.base.module.scss';
import { header_utilBar_cartIconAltText } from "@/lang/__generated__/ahnu/header_utilBar_cartIconAltText";

// Get the animation duration from the SCSS module as a number by removing the "ms"
// and parsing it as an integer.
const animationDuration = parseInt(
  S.badgeTransitionDuration.replace('ms', ''),
  10
);

interface ICartProps {
  /**
   * Counting products in cart.
   */
  count?: number;
}

/**
 * Header button for the cart, shows the number of items in cart.
 * Navigates to the cart page or opens the minicart when clicked.
 * @returns Cart icon button.
 */
const CartButton: FunctionComponent<ICartProps> = ({ count = 0 }) => {
  const [msg, msgf] = useLocaleMessage();

  const countText = count > 9 ? '9+' : count;
  const shouldShowCount = count > 0;
  const cartBadgeRef = useRef<HTMLSpanElement>(null);

  const CartIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Cart,
      AHNU: IconTypes.BagLight
    }),
    []
  );

  return (
    <Link
      href="/cart"
      variant="text"
      className={S.buttonWithIcon}
      ariaLabel={msg(header_utilBar_cartIconAltText)}
    >
      <Icon
        icon={CartIconType}
        className={S.cartIcon}
        size={IconSizeProp.SizeLG}
      />
      <Suspense>
        {/**
         * The `CSSTransition` component manages the state of the animated transitions via CSS classes.
         * @see http://reactcommunity.org/react-transition-group/css-transition
         */}
        <CSSTransition
          nodeRef={cartBadgeRef}
          in={shouldShowCount}
          timeout={animationDuration}
          classNames={{
            // States for when the badge being shown
            enter: S['cartBadge-enter'],
            enterActive: S['cartBadge-enter-active'],
            enterDone: S['cartBadge-enter-done'],

            // States for when the badge is being hidden and about to be removed from the DOM.
            exit: S['cartBadge-exit'],
            exitActive: S['cartBadge-exit-active'],
            exitDone: S['cartBadge-exit-done']
          }}
        >
          <span
            ref={cartBadgeRef}
            className={classes(S.cartBadge, shouldShowCount ? '' : S.hideCount)}
          >
            <span className={S.countText}>{countText}</span>
          </span>
        </CSSTransition>
      </Suspense>
    </Link>
  );
};

export default withEcommerceEnabled(CartButton);
