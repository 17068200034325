import { validEmail } from '@/react/view-models/Form/validation-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { DTO } from '@/type-utils';
import { makeObservable, observable } from 'mobx';
import { FormModel, validatable } from '../../Form';
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";

/**
 * Describes the fields of the Promotional Email opt-in form.
 */
export interface IPromotionalEmailForm {
  /** The user's email. */
  email: string;

  /** Whether the user is opting-in to promotional emails. */
  optIn: boolean;
}

/** @inheritdoc */
export class PromotionalEmailFormModel
  extends FormModel<IPromotionalEmailForm>
  implements IPromotionalEmailForm
{
  /** @inheritdoc */
  @observable
  @validatable({
    get name() {
      return msg(forms_field_email);
    },
    validations: [validEmail]
  })
  public email: string;

  /** @inheritdoc */
  @observable
  public optIn: boolean;

  /** @inheritdoc */
  public constructor(dto: DTO<IPromotionalEmailForm>) {
    super(dto);
    this.email = dto.email;
    this.optIn = dto.optIn;
    makeObservable(this);
  }

  /** @inheritDoc */
  public override toDTO(): DTO<IPromotionalEmailForm> {
    return { ...this };
  }
}
