'use client';

import { observer } from 'mobx-react-lite';
import {
  FunctionComponent,
  Suspense,
  useCallback,
  useContext,
  useState
} from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { PageType } from '@/services/models/Page';
import CartButton from '../../navigation/Header/components/CartButton';
import { HoverIntent } from '../../utility/HoverIntent';
import { MiniCart } from '../MiniCart';

import { useGlobalContext } from '../../../hooks/useGlobalContext';

import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import S from './styles.module.scss';
import { PageContext } from '../../templates/context/PageContext';

/**
 * Mini Cart Button used to control the Mini Cart drop down in the header.
 */
export const MiniCartButton: FunctionComponent = observer(() => {
  const [openMiniCart, setOpenMiniCart] = useState(false);
  const { cart } = useGlobalContext();

  const handleOpenCart = useCallback(() => setOpenMiniCart(true), []);
  const handleCloseCart = useCallback(() => setOpenMiniCart(false), []);

  const shouldOpenCart = openMiniCart && cart.totalProductQuantity > 0;

  // Check to see if the current page is the cart page.
  const page = useContext(PageContext)?.page;
  const pageType = page ? page.pageType : null;
  const isCartPage = pageType === PageType.Cart;

  return (
    <HoverIntent
      onHoverIn={handleOpenCart}
      onHoverOut={handleCloseCart}
      disabled={isCartPage}
    >
      <div className={S.container}>
        <Suspense>
          <CartButton count={cart.totalProductQuantity} />
        </Suspense>
        <div
          className={classes(S.miniCart, {
            // Only add the `visible` class if `shouldOpenCart` is truthy.
            [S.visible]: !!shouldOpenCart
          })}
          aria-hidden={!shouldOpenCart}
        >
          <Suspense>
            <CommonErrorBoundary fallback={null}>
              {openMiniCart && <MiniCart cart={cart} />}
            </CommonErrorBoundary>
          </Suspense>
        </div>
      </div>
    </HoverIntent>
  );
});
