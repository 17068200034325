'use client';

import { EventType } from '@/services/isomorphic/UserInteractionService';
import { IContentLink } from '@/services/models/Content';
import { FunctionComponent } from 'react';
import { Link } from '../../../../../../core-ui/Link';
import S from '../styles.base.module.scss';

interface IBlockLinkProps {
  /** A single block of navigation that contains an array of links. */
  block: IContentLink;
}

/**
 * Renders a single block of links, the final level of the navigation links.
 */
export const Block: FunctionComponent<IBlockLinkProps> = ({ block }) => {
  return (
    <div className={S.block}>
      {block.label && <div className={S.blockHeader}>{block.label}</div>}
      <ul className={S.blockLinks} role="menu">
        {block?.links?.map((link) => {
          return (
            <li
              key={link.id}
              onKeyDown={(event): void => {
                if (event.key === ' ' || event.key === 'Enter') {
                  event.stopPropagation();
                  if (event.key === ' ') {
                    event.preventDefault();
                  }
                }
              }}
              className={S.blockLinkWrapper}
              role="menuitem"
            >
              <Link
                className={S.blockLink}
                href={`/c${link.href}`}
                variant="base"
                interactionDetails={{
                  action: EventType.NavigationLink,
                  link: {
                    linkName: link.label,
                    linkCategory: 'top-navigation',
                    linkSection: block.label
                  }
                }}
              >
                {link.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
