import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { useContext } from 'react';
import { ApplePayAvailableContext } from '../internal/ApplePayAvailableContext';

/**
 * A hook to determine whether Apple Pay is available within the current context.
 * This should only be used within components with an `ApplePayProvider` ancestor.
 *
 * "Available" means that the Apple Pay JS SDK has been loaded and the browser
 * supports Apple Pay.
 *
 * @returns `true` if Apple Pay is available, `false` otherwise.
 */
export function useIsApplePayAvailable(): boolean {
  const value = useContext(ApplePayAvailableContext);

  if (value === null) {
    if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      LoggerService.warn(
        new Error(
          'A component is using useIsApplePayAvailable outside of an ApplePayProvider. This is likely a mistake. Did you forget to wrap your component in an ApplePayProvider?'
        )
      );
    }

    return false;
  }

  return value;
}
