'use client';

import { AnimatePresence, motion } from 'framer-motion';

import { FunctionComponent, ReactNode } from 'react';
import { Curtain } from '../Curtain';
import useConditionallyDisableScrolling from '../hooks/useConditionallyDisableScrolling';

import S from './styles.module.scss';

export interface IDrawerProps {
  /**
   * Called when the close button is clicked.
   */
  onClose: () => void;

  /**
   * Whether the component is currently open.
   */
  open: boolean;

  /**
   * The contents of the drawer.
   */
  children: ReactNode;

  /**
   * Extra classes for the root element.
   */
  rootClassName?: string;

  /**
   * Extra classes for the curtain.
   */
  curtainClassName?: string;

  /**
   * Extra classes for the drawer.
   */
  drawerClassName?: string;

  /**
   * Should the drawer appear from the left?
   * (Default is false, so from the right).
   */
  fromLeft?: boolean;

  /**
   * Accessible name for the drawer.
   */
  'aria-label'?: string;
}

/**
 * A drawer is an overlay, that slides into the view and is displayed above other content.
 */
export const Drawer: FunctionComponent<IDrawerProps> = ({
  onClose,
  open,
  children,
  fromLeft = false,
  'aria-label': ariaLabel,
  rootClassName = '',
  curtainClassName = '',
  drawerClassName = ''
}) => {
  useConditionallyDisableScrolling(open);

  return (
    <AnimatePresence>
      <motion.div
        className={[S.root, rootClassName, !open ? S.close : ''].join(' ')}
        role="dialog"
        aria-label={ariaLabel}
        initial={{ opacity: 0 }}
        animate={{ opacity: open ? 1 : 0, zIndex: open ? 100 : -1 }}
        exit={{ opacity: 0 }}
      >
        <Curtain onClose={onClose} className={curtainClassName} />
        <motion.div
          className={drawerClassName}
          tabIndex={-1}
          data-draw-from={fromLeft ? 'left' : 'right'}
          initial={{ x: fromLeft ? '-100%' : '100%' }}
          transition={{ type: 'tween' }}
          animate={{ x: open ? '0%' : '-100%' }}
          exit={{ x: fromLeft ? '-100%' : '100%' }}
        >
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
