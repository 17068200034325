'use client';

import { FunctionComponent, PropsWithChildren } from 'react';

import { ErrorAlertContext } from '../ErrorAlertContext';
import { useErrorAlertProviderValue } from '../ErrorAlertContext/useErrorAlertProviderValue';
import type { GlobalErrorAlertProvider } from '../GlobalErrorAlertProvider';

/**
 * This component initializes the context necessary for displaying error alerts.
 * It should be used in places where a more specific error alert is desired.
 * For global error alerts, use {@link GlobalErrorAlertProvider}.
 */
export const ErrorAlertProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const value = useErrorAlertProviderValue();

  return (
    <ErrorAlertContext.Provider value={value}>
      {children}
    </ErrorAlertContext.Provider>
  );
};
