'use client';

import { ReactElement, ReactNode, useMemo } from 'react';

import { IForm } from '@/react/view-models/Form';
import { FormContext, IFormContext } from '../FormContext';

export interface IFormProviderProps<T> {
  /** The form model that is held by this provider. */
  formModel: IForm<T>;

  /** The component children to be rendered inside the form provider and form component. */
  children: ReactNode;

  /** The form is currently busy processing. */
  isLoading: boolean;

  /**
   * Sets the isLoading parameter.
   * @param value - The value isLoading will be set to.
   */
  setIsLoading: (value: boolean) => void;
}

/**
 * The `Form` provider supplies wrapped components with the
 * current `FormModel` and a submit callback. This can be used to
 * handle a form fields component.
 */
export const FormProvider = <T,>(
  props: IFormProviderProps<T>
): ReactElement => {
  const { children, formModel, isLoading, setIsLoading } = props;

  const value = useMemo((): IFormContext => {
    return {
      form: formModel as IForm,
      isLoading,
      setIsLoading
    };
  }, [formModel, isLoading, setIsLoading]);

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
