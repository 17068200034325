'use client';

import type { FC } from 'react';
import { Logo } from '@/react/components/core-ui/Logo';
import { Link } from '@/react/components/core-ui/Link';

import S from './styles.base.module.scss';

/**
 * The header logo for the desktop and mobile headers.
 */
export const HeaderLogo: FC = () => {
  return (
    <Link variant="base" href="/" className={S.logo}>
      <Logo />
    </Link>
  );
};
