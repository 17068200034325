/** Types of supported social medias that will have their icon rendered. */
export enum SocialName {
  Instagram = 'instagram',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Youtube = 'youtube',
  Pinterest = 'pinterest',
  Blog = 'blog',
  TikTok = 'tiktok',
  Strava = 'strava'
}

/** Definition for the single social media link (icon). */
export interface ISocialItem {
  /** Type of the social media (instagram, facebook, SocialName enum). */
  name: SocialName;

  /** Title (for accessibility). */
  title: string;

  /** URL (href) for the social media page. */
  link: string;

  /** Size of the icon. */
  className?: string;
}
