import { toStatelessImmutable } from '@/utils/object-utils';
import type { DTO, Nullable } from '@/type-utils';
import Model from '../Model';
import CMSPlacementTypes from './CMSPlacementTypes';
import type { IContent } from './Interfaces/IContent';
import type { IPlacement } from './Interfaces/IPlacement';
import type { IPLPContent } from './Interfaces/IPLPContent';

/**
 * The content model is used to represent content that can be fed
 * into CMS components.
 * @deprecated This model is useful for the deprecated ContentService which
 * uses the CoreMediaService in the background.
 */
export default class ContentModel
  extends Model<DTO<IContent>>
  implements IContent
{
  /** @inheritdoc */
  public readonly id: string;

  /** @inheritdoc */
  public readonly name: string;

  /** @inheritdoc */
  public readonly pageTitle: Nullable<string>;

  /** @inheritdoc */
  public readonly pageDescription: Nullable<string>;

  /** @inheritdoc */
  public readonly seoText: Nullable<string>;

  /** @inheritdoc */
  public readonly segment: string;

  /** @inheritdoc */
  public readonly coveoSlug: Nullable<string> = null;

  /** @inheritdoc */
  public readonly placements: Array<IPlacement>;

  /**
   * Creates the new model.
   * @param content - A DTO of IContent.
   */
  public constructor(content: DTO<IContent>) {
    super(content);

    this.id = content.id;
    this.name = content.name;
    this.pageTitle = content.pageTitle;
    this.pageDescription = content.pageDescription;
    this.seoText = content.seoText;
    this.segment = content.segment;
    if (content.coveoSlug) this.coveoSlug = content.coveoSlug;
    this.placements = [...content.placements];
  }

  /**
   * Gets a placement based on a string name.
   * @see {@link IPlacement}
   * @param placementType - Placement name to get.
   * @returns A placement which holds items.
   * @throws {@link ResourceNotFoundError} When there is no placement.
   */
  public getPlacement(placementType: CMSPlacementTypes): Nullable<IPlacement> {
    const placement = this.placements.find(
      (placement) => placement.name === placementType
    );

    return placement ?? null;
  }

  /**
   * Gets multiple placements based on strings.
   * @see {@link IPlacement}
   * @param placementTypes - Array of strings representing placement names.
   * @returns A list of placements.
   */
  public getPlacements(
    placementTypes: Array<CMSPlacementTypes>
  ): Array<IPlacement> {
    const placements: Array<IPlacement> = [];
    placementTypes.forEach((placementType) => {
      const placement = this.getPlacement(placementType);
      if (placement) placements.push(placement);
    });

    return placements;
  }

  /**
   * Turns the IContent into the PLP placement object.
   * @param content - The page content for the given PLP.
   * @returns Returns an object holding the PLP content as placements.
   */
  public static getListPage(content: IContent): IPLPContent {
    const contentModel = ContentModel.from(content);

    const tiles = [];
    const tile1 = contentModel.getPlacement(CMSPlacementTypes.PLPContentTile1);
    const tile2 = contentModel.getPlacement(CMSPlacementTypes.PLPContentTile2);
    const tile3 = contentModel.getPlacement(CMSPlacementTypes.PLPContentTile3);
    const tile4 = contentModel.getPlacement(CMSPlacementTypes.PLPContentTile4);

    if (tile1?.items?.length) tiles.push(tile1);
    if (tile2?.items?.length) tiles.push(tile2);
    if (tile3?.items?.length) tiles.push(tile3);
    if (tile4?.items?.length) tiles.push(tile4);

    const plpContent = {
      topContent: contentModel.getPlacement(CMSPlacementTypes.PLPContentTop),
      bottomContent: contentModel.getPlacement(
        CMSPlacementTypes.PLPContentBottom
      ),
      contentTiles: tiles
    } as IPLPContent;

    return plpContent;
  }

  /** @inheritDoc */
  public toDTO(): DTO<IContent> {
    const {
      id,
      name,
      pageTitle,
      pageDescription,
      seoText,
      segment,
      coveoSlug,
      placements
    } = this;
    return toStatelessImmutable<Partial<DTO<IContent>>>({
      id,
      name,
      pageTitle,
      pageDescription,
      seoText,
      segment,
      coveoSlug,
      placements
    }) as DTO<IContent>;
  }
}
