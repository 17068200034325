import { mocked } from '@/configs';
import { Constructor } from '@/type-utils';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { EmailService } from './EmailService';
import IEmailPreferences from './IEmailPreferences';

const initialState = {};

/** Mock implementation of the EmailService. */
export default class EmailServiceMock extends ServiceMock<EmailService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): EmailService {
    return MockService.getMockOf(this.service) as EmailService;
  }

  /** @inheritdoc */
  public constructor(private service: Constructor<EmailService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(this.service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Constructor<EmailService>): void {
    const mockEnabled: boolean = mocked.EmailService;
    MockService.mockService(
      mockEnabled,
      service,
      {
        setCustomerPreferences: async (
          preferences: IEmailPreferences
        ): Promise<boolean> => {
          return true;
        }
      },
      {},
      this.state
    );
  }
}
