/**
 * @file Contains validation rules. (Validator functions).
 */
import { CardNetwork } from '@/constructs/CardNetwork';

import { Province } from '@/constructs/provinces/Province';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import I18NService, { msg, msgf } from '@/services/isomorphic/I18NService';
import { ReturnReason } from '@/services/models/Returns/order';
import { ReturnItemType } from '@/services/models/Returns/order/item';
import { getCardNetworkFromCardNumber } from '@/services/utils/credit-card-utils';
import { validatePhoneNumber } from '@/services/utils/phone-utils';
import {
  InvalidPhoneNumberCountryError,
  PhoneNumberTooLongError,
  PhoneNumberTooShortError,
  UnableToParsePhoneNumberError
} from '@/services/utils/phone-utils/errors';
import { isPOBoxLine, isValidEmail } from '@/utils/string-utils';
import type { IValidationResult, Validation } from './validatable';
import { forms_error_phoneNumber_tooShort } from "@/lang/__generated__/ahnu/forms_error_phoneNumber_tooShort";
import { forms_error_phoneNumber_tooLong } from "@/lang/__generated__/ahnu/forms_error_phoneNumber_tooLong";
import { forms_error_phoneNumber_invalidCountryCode } from "@/lang/__generated__/ahnu/forms_error_phoneNumber_invalidCountryCode";
import { forms_error_phoneNumber_default } from "@/lang/__generated__/ahnu/forms_error_phoneNumber_default";
import { forms_error_mustBeTrue } from "@/lang/__generated__/ahnu/forms_error_mustBeTrue";
import { checkout_shipping_errors_cannotShipToPOBox } from "@/lang/__generated__/ahnu/checkout_shipping_errors_cannotShipToPOBox";
import { forms_error_notEmpty } from "@/lang/__generated__/ahnu/forms_error_notEmpty";
import { forms_error_generic_invalidValue } from "@/lang/__generated__/ahnu/forms_error_generic_invalidValue";
import { forms_error_onlyNumeric } from "@/lang/__generated__/ahnu/forms_error_onlyNumeric";
import { forms_error_onlyLetters } from "@/lang/__generated__/ahnu/forms_error_onlyLetters";
import { forms_error_validEmail } from "@/lang/__generated__/ahnu/forms_error_validEmail";
import { forms_error_validPhoneNumber } from "@/lang/__generated__/ahnu/forms_error_validPhoneNumber";
import { forms_error_minLength } from "@/lang/__generated__/ahnu/forms_error_minLength";
import { forms_error_maxLength } from "@/lang/__generated__/ahnu/forms_error_maxLength";
import { forms_error_containsUpper } from "@/lang/__generated__/ahnu/forms_error_containsUpper";
import { forms_error_containsLower } from "@/lang/__generated__/ahnu/forms_error_containsLower";
import { forms_error_cardNumberLength } from "@/lang/__generated__/ahnu/forms_error_cardNumberLength";
import { forms_error_isCardNumber } from "@/lang/__generated__/ahnu/forms_error_isCardNumber";
import { forms_error_isMonth } from "@/lang/__generated__/ahnu/forms_error_isMonth";
import { forms_error_isYear } from "@/lang/__generated__/ahnu/forms_error_isYear";
import { forms_error_isState } from "@/lang/__generated__/ahnu/forms_error_isState";
import { forms_error_isReturnReason } from "@/lang/__generated__/ahnu/forms_error_isReturnReason";
import { forms_error_isReturnItemType } from "@/lang/__generated__/ahnu/forms_error_isReturnItemType";

/**
 * Validates that the input is not empty (after being trimmed).
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const nonEmpty: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  if (value.trim().length === 0) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_notEmpty, { name: name.toLowerCase() })
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * Will validate that the value of property matches the provided regular
 * expression.
 *
 * @param regex - The regular expression to test the value with.
 * @param message - A function to generate a custom error message for when the
 * validation fails.
 *
 * @returns Whether the property is valid and an error message.
 */
export const matchesRegex =
  (regex: RegExp, message?: () => string): Validation<string> =>
  (name: string, property: string, value: string): IValidationResult => {
    if (regex.test(value as string)) {
      return {
        isValid: true,
        errorMessage: null
      };
    }

    return {
      isValid: false,
      errorMessage: message
        ? message()
        : msgf(forms_error_generic_invalidValue, {
            name: name.toLowerCase()
          })
    };
  };

/**
 * Validates that the input is numeric.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const onlyNumeric: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  // Pass if empty
  if (value.length === 0) {
    return {
      isValid: true,
      errorMessage: null
    };
  }

  const isValid = /^[0-9]+$/.test(value);
  return {
    isValid,
    errorMessage: msgf(forms_error_onlyNumeric, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input is only letters.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const onlyLetters: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = /^[A-Za-z\s]*$/.test(value);
  return {
    isValid,
    errorMessage: isValid
      ? null
      : msgf(forms_error_onlyLetters, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input is a valid email.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const validEmail: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = isValidEmail(value);

  return {
    isValid,
    errorMessage: isValid
      ? null
      : msgf(forms_error_validEmail, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input is a valid phone number.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 *
 * @returns Whether the property is valid and an error message.
 * @throws
 */
export const validPhoneNumber: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  // If an empty string is passed...
  if (value === '') {
    // Return the "enter a valid phone number" message.
    return {
      isValid: false,
      errorMessage: msgf(forms_error_validPhoneNumber, {
        name: name.toLowerCase()
      })
    };
  }

  try {
    const isValid = validatePhoneNumber(
      value,
      I18NService.currentLocale.country
    );

    return {
      isValid,
      errorMessage: isValid
        ? null
        : msgf(forms_error_phoneNumber_default, { name: name.toLowerCase() })
    };
  } catch (error) {
    if (error instanceof UnableToParsePhoneNumberError) {
      let errorMessage;

      switch (true) {
        case error instanceof PhoneNumberTooShortError:
          errorMessage = msg(forms_error_phoneNumber_tooShort);
          break;
        case error instanceof PhoneNumberTooLongError:
          errorMessage = msg(forms_error_phoneNumber_tooLong);
          break;
        case error instanceof InvalidPhoneNumberCountryError:
          errorMessage = msg(forms_error_phoneNumber_invalidCountryCode);
          break;
        default:
          errorMessage = msg(forms_error_phoneNumber_default);
          break;
      }

      return {
        isValid: false,
        errorMessage
      };
    }

    throw error;
  }
};

/**
 * Given a number `n`, creates a validation function that will only allow
 * strings with `n` characters or more.
 *
 * **IMPORTANT:** `minLength` will NOT fail empty strings. This is to not fail
 * optional properties when not specified. If the value cannot be empty, include
 * {@link nonEmpty `nonEmpty`} as a validation too.
 *
 * @param n - Minimum length.
 *
 * @returns Whether the property is valid and an error message.
 */
export const minLength = (n: number): Validation<string> => {
  return (name: string, property: string, value: string): IValidationResult => {
    // Ignore if the string is empty.
    if (value.length === 0) {
      return {
        isValid: true,
        errorMessage: null
      };
    }

    if (value.length < n) {
      return {
        isValid: false,
        errorMessage: msgf(forms_error_minLength, {
          name: name.toLowerCase(),
          length: n
        })
      };
    }

    return {
      isValid: true,
      errorMessage: null
    };
  };
};

/**
 * Factory of validation. Given `n` creates a validation function
 * that will validate strings for maximum of n characters.
 * @param n - Max length.
 * @returns Whether the property is valid and an error message.
 */
export const maxLength = (n: number): Validation<string> => {
  return (name: string, property: string, value: string): IValidationResult => {
    if (value.length > n) {
      return {
        isValid: false,
        errorMessage: msgf(forms_error_maxLength, {
          name: name.toLowerCase(),
          length: n
        })
      };
    }

    return {
      isValid: true,
      errorMessage: null
    };
  };
};

/**
 * Validates that the input contains an uppercase character.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const containsUpper: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = /[A-Z]/.test(value);
  return {
    isValid,
    errorMessage: isValid
      ? null
      : msgf(forms_error_containsUpper, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input contains a lowercase character.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const containsLower: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = /[a-z]/.test(value);
  return {
    isValid,
    errorMessage: isValid
      ? null
      : msgf(forms_error_containsLower, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input is a true value. This is used
 * for mandatory agreement.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const mustBeTrue: Validation<boolean> = (
  name: string,
  property: string,
  value: boolean
): IValidationResult => {
  return {
    isValid: value,
    errorMessage: value ? null : msg(forms_error_mustBeTrue)
  };
};

/**
 * Validates that the input is a credit card.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isCardNumber: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const { length } = value.trim();

  if (length < 13 || length > 19) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_cardNumberLength, {
        name: name.toLowerCase()
      })
    };
  }

  if (
    // Allow masked credit card numbers
    !value.startsWith('*') &&
    getCardNetworkFromCardNumber(value) === CardNetwork.Unknown
  ) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_isCardNumber, {
        name: name.toLowerCase()
      })
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * Validates that the input is a month value and provides
 * the proper error messaging.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isMonth: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = /^[0-9]+$/.test(value);
  return {
    isValid,
    errorMessage: msgf(forms_error_isMonth, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input is a year input and provides the
 * proper error messaging.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isYear: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  const isValid = /^[0-9]+$/.test(value);
  return {
    isValid,
    errorMessage: msgf(forms_error_isYear, { name: name.toLowerCase() })
  };
};

/**
 * Validates that the input of a state to make sure it matches the sites state
 * enum.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isState: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  if (!Object.keys(Province).includes(value)) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_isState, { name: name.toLowerCase() })
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * Validates that the input of the return reason radio button is a valid
 * return reason. By default the form should have an empty string.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isReturnReason: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  if (!Object.values(ReturnReason).includes(value as ReturnReason)) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_isReturnReason, {
        name: name.toLowerCase()
      })
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * Validates that the input of the return item type radio button is a valid
 * return item type. By default the form should have an empty string.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const isReturnItemType: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  if (!Object.values(ReturnItemType).includes(value as ReturnItemType)) {
    return {
      isValid: false,
      errorMessage: msgf(forms_error_isReturnItemType, {
        name: name.toLowerCase()
      })
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * Checks if a given address line is valid.
 *
 * @param name - Resource string.
 * @param property - Property key.
 * @param value - The value to validate.
 * @returns Whether the property is valid and an error message.
 */
export const validAddressLine: Validation<string> = (
  name: string,
  property: string,
  value: string
): IValidationResult => {
  // Step 1: Check if the line is a PO Box and fail if shipping to PO Boxes is
  // disabled.
  const config = ConfigurationService.getConfig('checkout');
  const allowShippingToPOBoxes = config.getSetting(
    'allowShippingToPOBoxes'
  ).value;

  if (!allowShippingToPOBoxes && isPOBoxLine(value)) {
    return {
      isValid: false,
      errorMessage: msg(checkout_shipping_errors_cannotShipToPOBox)
    };
  }

  return {
    isValid: true,
    errorMessage: null
  };
};

/**
 * This is used for mandatory opt-in (Checkbox).
 *
 * @param message - A function to generate a custom error message for when the
 * validation fails.
 * @returns Whether the property is valid and an error message.
 */
export const requiredOptIn =
  (message: () => string): Validation<boolean> =>
  (name: string, property: string, value: boolean): IValidationResult => {
    if (!value) {
      return {
        isValid: false,
        errorMessage: message()
      };
    }

    return {
      isValid: true,
      errorMessage: null
    };
  };
