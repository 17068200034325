'use client';

import { useLocale } from '@/react/hooks/useLocale';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { Suspense, type FC } from 'react';
import { Breakpoint, Breakpoints, Default } from '../../core-ui/Breakpoints';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { Unprintable } from '../../utility/Unprintable';
import { HeaderBanners } from './components/HeaderBanners';
import { DesktopHeader } from './components/Navbar/Desktop/components/DesktopHeader';
import { MobileHeader } from './components/Navbar/Mobile/components/MobileHeader';
import { TopUtilityBar } from './components/TopUtilityBar';
import { IHeaderProps } from './IHeaderProps';
import { StickyHeaderAB } from './personalization/ABTests/StickyHeaderAB';

import S from './styles.base.module.scss';

/**
 * Responsive header bar containing navigation, search, cart.
 * @throws Error if navigationContent is undefined.
 */
export const DefaultHeader: FC<IHeaderProps> = ({
  contentBanner = null,
  navbarProps
}) => {
  const { language, country } = useLocale();
  const personalizationConfig = ConfigurationService.getConfig(
    'personalization',
    language,
    country
  );
  const isRunningStickyHeaderABTest = personalizationConfig.getSetting(
    'tests.stickyHeaderABTestEnabled'
  ).value;

  const searchConfig = ConfigurationService.getConfig(
    'search',
    language,
    country
  );
  const isSearchEnabled = searchConfig.getSetting('enableSearch').value;

  const headerConfig = ConfigurationService.getConfig(
    'header',
    language,
    country
  );
  const menuConfig = ConfigurationService.getConfig('menu', language, country);
  const showUtilityLinks = menuConfig.getSetting(
    'mainMenu.showUtilityLinks'
  ).value;

  const isBannerAtTop = headerConfig.getSetting('isBannerAtTop').value;

  // Allow for simplified (ie. mobile style) header layout on desktop
  // displays for some brands
  const isSimplifiedDesktopLayout = headerConfig.getSetting(
    'isSimplifiedDesktopLayout'
  ).value;

  const headerComponent = (
    <Breakpoints>
      <Breakpoint media="desktop">
        <Unprintable>
          {showUtilityLinks && <TopUtilityBar />}
          <div className={S.headerWrapper}>
            {isBannerAtTop && <HeaderBanners contentBanner={contentBanner} />}
            {isSimplifiedDesktopLayout ? (
              <header className={S.header}>
                <Suspense fallback={<SkeletonLoader />}>
                  <MobileHeader isSearchEnabled={isSearchEnabled} />
                </Suspense>
              </header>
            ) : (
              <header className={S.header}>
                <Suspense fallback={<SkeletonLoader />}>
                  <DesktopHeader
                    navbarProps={navbarProps}
                    isSearchEnabled={isSearchEnabled}
                    showUtilityLinks={showUtilityLinks}
                  />
                </Suspense>
              </header>
            )}
            {!isBannerAtTop && <HeaderBanners contentBanner={contentBanner} />}
          </div>
        </Unprintable>
      </Breakpoint>

      <Default>
        <Unprintable>
          <div className={S.headerWrapper}>
            {isBannerAtTop && <HeaderBanners contentBanner={contentBanner} />}
            <header className={S.header}>
              <Suspense fallback={<SkeletonLoader />}>
                <MobileHeader isSearchEnabled={isSearchEnabled} />
              </Suspense>
            </header>
            {!isBannerAtTop && <HeaderBanners contentBanner={contentBanner} />}
          </div>
        </Unprintable>
      </Default>
    </Breakpoints>
  );

  return isRunningStickyHeaderABTest ? (
    <StickyHeaderAB>{headerComponent}</StickyHeaderAB>
  ) : (
    headerComponent
  );
};

export type { IHeaderProps };
