'use client';

import { IHeaderCategory } from '@/services/models/Content';
import { FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Section } from './Section';

import S from '../styles.base.module.scss';

/**
 * Props for the desktop navigation bar block component.
 */
export interface INavigationDropdownProps {
  /** A single category from the navigation content. */
  category: IHeaderCategory;

  /** The id of the category that should be visible currently. */
  visibleCategoryID: string | null;

  /**
   * Total time it takes for the menu to fully appear
   * with the section sliding up and everything.
   */
  totalAnimationTimeMS: number;

  /**
   * Animation time of a section. (Slide up time).
   */
  sectionTransitionMS: number;
}

/**
 * Renders a dropdown menu that represents a category of the navbar.
 */
export const Dropdown: FunctionComponent<INavigationDropdownProps> = ({
  category,
  visibleCategoryID,
  totalAnimationTimeMS,
  sectionTransitionMS
}) => {
  /*
    So if the visibleCategoryID === null, then the Navbar level 1 link
    (on the horizontal bar) triggered the mouseleave event,
    and no other dropdown became visible, thus visibleCateogryId is null
    so we have the time to do a cool fade out effect.
    On the other hand, if another dropdown is visible, then
    set exit time to 0, so this dropdown disappears immediately and doesn't
    overlap with the next dropdown that is trying to appear
  */
  const exitTime = visibleCategoryID === null ? 300 : 0;
  const isVisible = category.id === visibleCategoryID;

  /**
   * If the dropdown is fading out, remove the delay between sections and
   * allow them to slide down together.
   */
  let delayPerSection = 0;
  if (isVisible) {
    /**
     * If we want all of the animations to be done in 500ms (for example),
     * then we have to consider the length of the section animations as well.
     * If total length is 500ms, but sections take 300ms to animate from bottom to top
     * then there's only 200ms of space for delays, since after the first section starts animating
     * the last section has to start animating in 200ms in order to be done before 500ms.
     */
    const delayRoom = totalAnimationTimeMS - sectionTransitionMS;
    delayPerSection = delayRoom / category.blocks.length;
  }

  return (
    <CSSTransition
      in={isVisible}
      timeout={{
        enter: 0,
        // After the in={} property changes to false, wait this amount of
        // milliseconds before actually hiding the node
        exit: exitTime
      }}
      classNames={{
        enter: S.dropdownVisible,
        enterDone: S.dropdownVisible,
        exit: S.dropdownExitPrepare,
        exitActive: S.dropdownExiting
      }}
    >
      <ul className={S.dropdown} role="menu" key={category.id}>
        {category.blocks.map((section, index) => {
          // Category blocks appear as sections (columns), hence the name
          return (
            <Section
              key={section.id ?? index}
              section={section}
              isVisible={isVisible}
              index={index}
              transitionMS={sectionTransitionMS}
              transitionDelayMS={delayPerSection * index}
            />
          );
        })}
      </ul>
    </CSSTransition>
  );
};
