'use client';

import { useCallback, type FunctionComponent } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import PayPalNVPService from '@/services/isomorphic/integrations/PayPalNVPService';
import { MOCK_CART_DTO } from '@/services/models/Cart/mocks';
import type { IGetExpressCheckoutDetailsResponse } from '@/services/serverless/integrations/ServerPayPalNVPService';
import type CartVM from '@/react/view-models/CartVM';

import S, { buttonHeight } from './styles.module.scss';

export interface IPayPalButtonProps {
  /** The cart in use, to create the order from it. */
  cart: CartVM;

  /**
   * The mode to use, which will determine the behavior of the button.
   *
   * - `normal`: Meant to be used on "full checkout", where the user fills in their
   *   information via the checkout form and _then_ selects PayPal as the payment method.
   *   Bear in mind that the user won't be able to override the shipping address fields
   *   in the PayPal modal, since they would have already filled them out in the checkout form.
   *
   * - `express`: Meant to be used on "express checkout", where the user is taken to the
   *   PayPal modal right away to fill in their information. In this mode, the user must
   *   enter the shipping address from the PayPal modal, either by selecting one of the
   *   addresses saved on their account or by entering a new one.
   */
  mode?: 'normal' | 'express';

  /**
   * Callback to run when the PayPal transaction has been approved by the user.
   *
   * @param result - The result from the `GetExpressCheckoutDetails` call made after the
   * user approves the transaction.
   *
   * @see [GetExpressCheckoutDetails API Operation (NVP)](https://developer.paypal.com/api/nvp-soap/get-express-checkout-details-nvp/)
   * @see {@link PayPalNVPService.getExpressCheckoutDetails}
   */
  onApprove?: (result: IGetExpressCheckoutDetailsResponse) => void;

  /**
   * Callback to run when the PayPal transaction is cancelled by the user.
   */
  onCancel?: () => void;
}

/** Renders a button that allows the user to pay an order with PayPal. */
export const PayPalButton: FunctionComponent<IPayPalButtonProps> = ({
  cart,
  mode = 'normal',
  onApprove,
  onCancel
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  // TODO: Listen for changes in shipping options/address for Express Checkout.
  const createOrder = useCallback(async () => {
    const dto = cart.toDTO();
    const expressCheckout = PayPalNVPService.expressCheckoutFromDECACart(
      dto ?? MOCK_CART_DTO,
      mode === 'express'
    );

    const { TOKEN } =
      await PayPalNVPService.setExpressCheckout(expressCheckout);

    return TOKEN;
  }, [cart, mode]);

  const onApproveInternal = useCallback(
    async (data: { orderID: string }) => {
      const expressCheckoutDetails =
        await PayPalNVPService.getExpressCheckoutDetails({
          TOKEN: data.orderID
        });

      if (onApprove) {
        onApprove(expressCheckoutDetails);
      }
    },
    [onApprove]
  );

  // The `buttonHeight` variable exported from the SCSS module should be parsed as an
  // integer. Any non-digit character should also be removed, leaving only the number
  // part of it.
  const parsedButtonHeight = parseInt(
    (buttonHeight as string).replaceAll(/\D/g, ''),
    10
  );

  return (
    <div className={S.payPalButton}>
      {isPending && <div className={S.spinner} />}

      <PayPalButtons
        fundingSource="paypal"
        style={{
          layout: 'horizontal',
          label: 'checkout',
          tagline: false,
          height: parsedButtonHeight
        }}
        createOrder={createOrder}
        onApprove={onApproveInternal}
        onCancel={() => {
          if (onCancel) onCancel();
        }}
      />
    </div>
  );
};
