'use client';

import type { PropsWithChildren, FC } from 'react';
import S from '../../styles.base.module.scss';

/**
 * Simple component that centralizes the style of each individual nav item
 * in the mobile navigation.
 */
export const MobileNavItem: FC<PropsWithChildren> = ({ children }) => {
  return <li className={S.navItem}>{children}</li>;
};
