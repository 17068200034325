'use client';

import { FunctionComponent, useState } from 'react';
import { Form } from '@/react/components/core-ui/Form';
import { PromotionalEmailFormModel } from '@/react/view-models/forms/PromotionalEmail';
import EmailService from '@/services/isomorphic/EmailService';
import { withEcommerceEnabled } from '@/react/components/utility/EcommerceEnabled';

import { useDebounce } from '@/react/hooks/useDebounce';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import { useLocale } from '@/react/hooks/useLocale';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';

import { PromotionalEmailSignUpFormFields } from './PromotionalEmailSignUpFormFields';

import S from './styles.base.module.scss';
import { footer_emailForm_success } from "@/lang/__generated__/ahnu/footer_emailForm_success";

/** Promotional email sign-up form. */
const PromotionalEmailSignUpForm: FunctionComponent = () => {
  const locale = useLocale();
  const [msg, msgf] = useLocaleMessage();

  const [form] = useState(
    () => new PromotionalEmailFormModel({ email: '', optIn: true })
  );
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const debounce = useDebounce();

  const handleSubmit = async (): Promise<void> => {
    setShowSuccessMsg(false);
    const isSuccess = await EmailService.setCustomerPreferences({
      email: form.email,
      locale: locale.toString(),
      receivePromotionalEmails: true
    });
    if (isSuccess) {
      // Send hashed email to GTM when success
      UserInteractionService.makeAction({
        action: EventType.SignupSuccess,
        customer_email: form.email
      });

      setShowSuccessMsg(true);
      debounce(5000, () => setShowSuccessMsg(false));
      form.clear();
    } else {
      UserInteractionService.makeAction({
        action: EventType.SignupError
      });
    }
  };

  return (
    <div className={S.form}>
      <Form form={form} submit={handleSubmit}>
        <PromotionalEmailSignUpFormFields />
      </Form>
      {showSuccessMsg && (
        <span className={S.successMessage}>
          {msg(footer_emailForm_success)}
        </span>
      )}
    </div>
  );
};

export default withEcommerceEnabled(PromotionalEmailSignUpForm);
