/**
 * A list of all available icon animations and their access name and the string used
 * to trigger the animation. Animation names are classes set in icon style module.
 */
export enum IconAnimations {
  /**
   * Rotates 45 degrees clockwise. Can be changed by setting --rotation variable.
   */
  Clockwise = 'clockwise',
  /**
   * Rotates 45 degrees counterclockwise. Can be changed by setting --rotation variable.
   */
  CounterClockwise = 'counter-clockwise'
}
