import { FunctionComponent, ReactElement } from 'react';
import { msg } from '@/services/isomorphic/I18NService';
import { Breakpoint, Breakpoints, Default } from '../Breakpoints';
import { general_logoAlt } from "@/lang/__generated__/ahnu/general_logoAlt";

/**
 * Ahnu logo component.
 */
export const AhnuLogo: FunctionComponent = () => {
  const logoImage = (
    width: number = 135,
    height: number = 22
  ): ReactElement => {
    const logoAlt = msg(general_logoAlt) as string;
    return (
      <img src="/images/logo.svg" alt={logoAlt} width={width} height={height} />
    );
  };

  return (
    <Breakpoints>
      <Breakpoint media="desktop">{logoImage(150, 24)}</Breakpoint>
      <Default>{logoImage(135, 22)}</Default>
    </Breakpoints>
  );
};
