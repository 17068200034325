import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Service from '../../Service';
import EmailServiceMock from './EmailServiceMock';
import IEmailPreferences from './IEmailPreferences';

/**
 * Abstracts email-related functionalities.
 * Interfaces with the `ServerEmailService` via the API endpoints at `@/pages/api/mail`.
 */
export class EmailService extends Service {
  private client: AxiosInstance;

  /** Initialize the service. */
  public constructor() {
    super();

    this.client = axios.create({
      baseURL: '/api/mail'
    });
  }

  /**
   * Sets a customer's email communication preferences.
   * @param preferences - New email preferences.
   * @returns Promise that resolves with a `true` value on successful operation.
   */
  public async setCustomerPreferences(
    preferences: IEmailPreferences
  ): Promise<boolean> {
    const res: AxiosResponse<{
      message: string;
      preferences: IEmailPreferences;
    }> = await this.client.post('customer-preferences', preferences);

    return res.status === 200;
  }
}

export default EmailService.withMock(new EmailServiceMock(EmailService));
