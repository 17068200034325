'use client';

import { FunctionComponent } from 'react';

import { CategoryLink } from '../CategoryLink';
import { CategoryBlock } from '../CategoryBlock';
import { MobileDrawer } from '../../MobileDrawer';
import { MobileNestedDrawers } from '../../MobileNestedDrawers';

import { ICategoryProps } from './ICategoryProps';

import S from '../../../styles.base.module.scss';

/**
 * Renders a button for the mobile navigation that (once pressed) opens a
 * Drawer component which contains blocks (further layers of navigation).
 */
const Category: FunctionComponent<ICategoryProps> = ({ category }) => {
  const hasChildren = category.blocks.length > 0;

  if (!hasChildren) {
    return <CategoryLink category={category} />;
  }

  return (
    <MobileDrawer label={category.label}>
      <ul className={S.nav}>
        <li className={S.navItem}>
          <span className={`${S.navLink} ${S.navLinkHeader}`}>
            {category.label}
          </span>
        </li>
        {category.blocks.map((block) => (
          <MobileNestedDrawers key={`${block.id}-mobile-nested-drawer`}>
            <CategoryBlock key={`${block.id}-category-block`} block={block} />
          </MobileNestedDrawers>
        ))}
      </ul>
    </MobileDrawer>
  );
};

export default Category;
