'use client';

import {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { IForm, IFormErrors, IInvalidProperty } from '@/react/view-models/Form';
import UserInteractionService, {
  InteractionDetails
} from '@/services/isomorphic/UserInteractionService';
import type { MaybePromise } from '@/type-utils';
import IStylable from '../../traits/IStylable';

import { FormProvider } from './FormProvider';

export interface IFormProps<T extends IForm> extends IStylable {
  /** The form model that sets the model for this Form component. */
  form: T;

  /**
   * The submit trigger for the form. This contains the original callback
   * as well as hooked functionality added in the {@link Form} component itself.
   * @param [event] - The submit event from triggering the button.
   * @returns `void` or a promise that resolves to `void`.
   */
  submit: (
    event?: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => MaybePromise<void>;

  /**
   * Allows you to make a user interaction event when the form is submitted.
   *
   * This will allow the use of the {@link UserInteractionService} to make
   * analytics events in GTM, Coveo, etc..
   */
  submitInteractionDetails?: InteractionDetails;

  /**
   * This does the same as the above, but makes an interaction event when the user enters
   * the form.
   */
  enterInteractionDetails?: InteractionDetails;

  /**
   * This interaction fires when there is an error on submit validation.
   */
  errorInteractionDetails?: InteractionDetails;

  /**
   * This interaction fires when the form is submitted successfully.
   */
  successInteractionDetails?: InteractionDetails;

  /**
   * If not included uses the generic error handling provided in the `Form` component.
   * If included then error handling must be provided by the wrapping component through
   * the submit callback.
   *
   * Think carefully before using this and if adding another feature would make more sense than
   * using something totally custom.
   */
  useCustomErrorHandling?: boolean;

  /** The component children to be rendered inside the form provider and form component. */
  children: ReactNode;
}

/**
 * The base Form component, this takes in a `FormModel`, a submit callback, and interaction
 * details and wraps children components with an HTML form element and a provider that
 * supplies the children with these items.
 * This allows us to hook the submit button and use interaction details in parallel
 * to make calls to the `UserInteractionService`.
 */
export const Form = <T extends IForm>(props: IFormProps<T>): ReactElement => {
  const {
    className,
    form,
    submit,
    submitInteractionDetails,
    enterInteractionDetails,
    errorInteractionDetails,
    successInteractionDetails,
    useCustomErrorHandling = false,
    children
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (
    event: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ): Promise<void> => {
    event.preventDefault();

    // Set is loading so that we can disable form submit.
    setIsLoading(true);

    try {
      // This is the stock error handling.
      if (!useCustomErrorHandling) {
        // Keep in mind 'validate' will 'touch' all required form fields.
        const { isValid, invalidProperties } = form.validate;

        const mappedErrors: IFormErrors = {};

        invalidProperties.forEach((ele: IInvalidProperty<T>) => {
          mappedErrors[ele.propertyName as keyof IFormErrors] = ele.errors;
        });

        // Does not submit if invalid.
        if (isValid) {
          // If submission interaction details have been provided, make the action.
          if (submitInteractionDetails)
            UserInteractionService.makeAction(submitInteractionDetails);

          await submit(event);
          // If success interaction has been provided it will fire after the submit completes.
          if (successInteractionDetails)
            UserInteractionService.makeAction(successInteractionDetails);
        } else {
          // If error interaction details have been provided, make the action.
          if (errorInteractionDetails)
            UserInteractionService.makeAction(errorInteractionDetails);

          moveToForm();
        }
      } else {
        // Custom error handling means that the submit method should contain all error handling.
        await submit(event);
        // If success interaction has been provided it will fire after the submit completes.
        if (successInteractionDetails)
          UserInteractionService.makeAction(successInteractionDetails);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  // Scrolls back to the form after validation fails on submit.
  const moveToForm = (): void => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  };
  // Handles the focusin event on the form. It triggers only once and will send
  // whatever 'userInteraction' event is assigned to the form.
  useEffect(() => {
    let formEntered = false;
    const focusIn = (): void => {
      if (!formEntered && enterInteractionDetails) {
        UserInteractionService.makeAction(enterInteractionDetails);
      }
      formEntered = true;
    };

    if (formRef.current) {
      formRef.current.addEventListener('focusin', focusIn);
    }

    return (): void => formRef.current?.removeEventListener('focusin', focusIn);
  }, []);

  return (
    <FormProvider
      formModel={form}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <form
        ref={formRef}
        className={className}
        onSubmit={handleSubmit}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};
