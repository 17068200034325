'use client';

import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import CartVM from '@/react/view-models/CartVM';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ErrorAlert } from '../../core-ui/ErrorAlert';
import S from './styles.base.module.scss';

/**
 * Props for the `MiniCartErrorAlert` component.
 */
export interface IMiniCartErrorAlertProps {
  /**
   * The cart view model to watch for item quantity changes.
   */
  cart: CartVM;
}

/**
 * Error alert that is displayed in the mini cart when an error occurs.
 */
export const MiniCartErrorAlert = observer(function MiniCartErrorAlert({
  cart
}: IMiniCartErrorAlertProps) {
  const { error, closeAlert } = useErrorAlert();

  useEffect(
    /**
     * Automatically clears the error alert when the cart quantity changes.
     *
     * This is a QOL/UX improvement to prevent the error text from
     * persisting when it's no longer relevant.
     */
    function closeErrorAlertOnCartQuantityChange() {
      closeAlert();
    },
    [cart.totalItemQuantity, closeAlert]
  );

  if (!Boolean(error)) {
    return null;
  }

  return (
    <ErrorAlert
      error={error}
      onClose={closeAlert}
      showAlertIcon={false}
      containerClassName={S.errorAlertContainer}
      messageClassName={S.errorAlertMessage}
    />
  );
});
