import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { LikelyMisconfigurationError } from '@/utils/errors';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import type { PropsWithChildren, FunctionComponent } from 'react';

/**
 * Holds the context provider for the PayPal Script. Every use of PayPal buttons must be
 * wrapped by this provider.
 *
 * @throws A {@link LikelyMisconfigurationError} if the PayPal clientID is not configured for
 * the current site.
 */
export const PayPalProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const config = ConfigurationService.getConfig('paypal');
  const clientId = config.getSetting('clientID').value;

  if (!clientId) {
    throw new LikelyMisconfigurationError(
      `Cannot load the PayPalProvider: clientID is not configured for the current site.`
    );
  }

  return (
    <PayPalScriptProvider
      options={{ clientId, components: 'buttons', intent: 'order' }}
    >
      {children}
    </PayPalScriptProvider>
  );
};
