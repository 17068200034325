'use client';

import { FunctionComponent } from 'react';

import { Button } from '@/react/components/core-ui/Button';
import { Spinner } from '@/react/components/core-ui/Spinner';
import { PromotionalEmailFormModel } from '@/react/view-models/forms/PromotionalEmail';
import { msg } from '@/services/isomorphic/I18NService';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useFormContext } from '../../../../../hooks/useFormContext';

import S from './styles.base.module.scss';
import { forms_field_enterEmail } from "@/lang/__generated__/ahnu/forms_field_enterEmail";
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { footer_emailForm_submit } from "@/lang/__generated__/ahnu/footer_emailForm_submit";
import { footer_emailForm_message } from "@/lang/__generated__/ahnu/footer_emailForm_message";

/** Represents shared props of promotional email sign-up form components. */
interface IFieldProps {
  /** The observable form model for promotional emails. */
  form: PromotionalEmailFormModel;

  /** Whether the form is being submitted. */
  isLoading?: boolean;
}

/** A custom email text-field. */
const EmailInput = observer<IFieldProps>(({ form }) => (
  <div className={S.container}>
    <label className={S.emailLabel} htmlFor="email">
      {msg(forms_field_enterEmail)}
    </label>
    <input
      name="email"
      type="email"
      value={form.email}
      placeholder={msg(forms_field_email)}
      className={
        !form.isValid && form.getTouch('email') ? S.emailError : S.emailInput
      }
      onBlur={() => form.setTouch('email')}
      onInput={(e) =>
        runInAction(() => {
          form.email = e.currentTarget.value;
        })
      }
    />
  </div>
));

/** The submit ("JOIN") button to opt-in to promotional emails. */
const JoinButton = observer<IFieldProps>(({ form, isLoading = false }) => (
  <Button
    className={S.joinButton}
    disabled={isLoading || !form.isValid || !form.optIn}
    submit
  >
    {isLoading ? (
      <Spinner className={S.spinner} />
    ) : (
      msg(footer_emailForm_submit)
    )}
  </Button>
));

/** Displays validation errors for the promotional email sign-up form. */
const ErrorMessage = observer<IFieldProps>(({ form }) => {
  const { errors } = form.validateProperty('email');
  if (errors.length === 0) {
    return null;
  }

  return <div className={S.errorMessage}>{errors[0]}</div>;
});

/**
 * A form the user can fill up to login.
 * @throws If the form context doesn't wrap this component or the
 * `FormModel` is of the wrong type.
 */
export const PromotionalEmailSignUpFormFields: FunctionComponent = () => {
  const { form, isLoading } = useFormContext(PromotionalEmailFormModel);

  return (
    <>
      <h4 className={S.title}>{msg(footer_emailForm_message)}</h4>
      <div className={S.fieldGroup}>
        <EmailInput form={form} />
        <JoinButton form={form} isLoading={isLoading} />
      </div>
      <ErrorMessage form={form} />
    </>
  );
};
