'use client';

import { FunctionComponent, useState, useCallback } from 'react';
import {
  NavigationContentModel,
  type IHeaderCategory
} from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';

import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';

import { Drawer } from '@/react/components/core-ui/Drawer';
import { Icon, IconSizeProp, IconTypes } from '@/react/components/core-ui/Icon';

import { useLocale } from '@/react/hooks/useLocale';
import { MobileNestedDrawers } from './components/MobileNestedDrawers';
import { INavigationLink } from './components/NavigationLink/INavigationLinkProps';
import { useMobileDrawer } from './useMobileDrawer';

import { MobileUtilityLinks } from './components/MobileUtilityLinks';
import { MobileNavItem } from './components/MobileNavItem';
import { NavigationLink } from './components/NavigationLink';
import NavigationBack from './components/NavigationBack';
import Category from './components/Categories/Category';

import S from './styles.base.module.scss';
import { header_navigation_open } from "@/lang/__generated__/ahnu/header_navigation_open";

/**
 * The Mobile navbar component that acts as a drawer.
 */
export const Mobile: FunctionComponent = () => {
  const [msg] = useLocaleMessage();
  const { language, country } = useLocale();
  const { isOpen, openDrawer, closeDrawer } = useMobileDrawer();
  const navigationContentModel = null;
  const categories: Array<IHeaderCategory> = [];

  // Provide an option to override the CMS values for the menu.
  // The 'menu' config structure allows us to set a basic, static
  // menu structure of links.
  const menuConfig = ConfigurationService.getConfig('menu', language, country);
  const mainMenu = menuConfig.getSetting('mainMenu').value;
  const showUtilityLinks = menuConfig.getSetting(
    'mainMenu.showUtilityLinks'
  ).value;
  const isCmsOverride = mainMenu.overrideCmsValues.value;
  const configLinks = mainMenu.links.value?.map(({ href, id, label }) => {
    return {
      id: id.value,
      label: label.value,
      href: href.value
    } as INavigationLink;
  });

  const MenuIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Bars,
      AHNU: IconTypes.BarsLight
    }),
    []
  );

  // Show the navigation back button on parent / root drawer.
  const showBackButtonOnParentDrawer = useBrandLocaleValue<boolean>(
    () => ({
      default: true
    }),
    []
  );

  return (
    <>
      <button
        className={S.buttonWithIcon}
        onClick={openDrawer}
        type="button"
        title={msg(header_navigation_open)}
        aria-label={msg(header_navigation_open)}
      >
        <Icon icon={MenuIconType} size={IconSizeProp.SizeLG} />
      </button>

      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        fromLeft
        drawerClassName={S.parentDrawer}
      >
        {showBackButtonOnParentDrawer && <NavigationBack />}
        <ul className={S.nav}>
          {!isCmsOverride ? (
            <>
              {categories.map((category) => {
                return (
                  <MobileNestedDrawers key={`${category.id}-nested-drawers`}>
                    <Category category={category} />
                  </MobileNestedDrawers>
                );
              })}
            </>
          ) : (
            configLinks.map((item) => {
              return (
                <MobileNavItem key={item.id}>
                  <NavigationLink item={item} />
                </MobileNavItem>
              );
            })
          )}
          {showUtilityLinks && <MobileUtilityLinks />}
        </ul>
      </Drawer>
    </>
  );
};
